<template>
<b-button @click="onClick" :tabindex="tabindex" :disabled="innerDisabled" :variant="variant">
  <div ref="button" v-if="!inProgress">
    <slot v-if="!completed" />
    <div v-else :style="{ 'width': computedWidth, 'height': computedHeight }" class="text-secondary">
      <i class="fas fa-check" />
    </div>
  </div>
  <div v-else :style="{ 'width': computedWidth, 'height': computedHeight }">
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin" />
    </div>
  </div>
</b-button>
</template>

<script>
import fullStory from '@/clients/fullStory';
import cursorHandler from './cursorHandler';

export default {
  name: 'ActivityButton',

  props: {
    action: {
      type: Function,
      required: false,
      default() {}
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    event: {
      type: String,
      required: false,
      default: null
    },
    variant: {
      type: String,
      required: false,
      default: 'primary'
    },
    tabindex: {
      type: String,
      required: false,
      default: undefined
    }
  },

  inject: ['logger'],

  data() {
    return {
      inProgress: false,
      completed: false,
      width: null,
      height: null
    };
  },

  computed: {
    innerDisabled() {
      return this.inProgress || this.disabled;
    },
    computedHeight() {
      return this.height && `${this.height}px`;
    },
    computedWidth() {
      return this.width && `${this.width}px`;
    }
  },

  methods: {
    async onClick() {
      this.width = this.$refs.button?.clientWidth || 60;
      this.height = this.$refs.button?.clientHeight || 31;
      const inProgressAsync = new Promise(resolve => setTimeout(resolve, 10)).then(() => 'RACE');
      this.sendEvent();

      let actionCompletedSuccessFully = false;
      if (this.action) {
        try {
          cursorHandler.setLoading(true);
          const actionAsync = this.action();
          const raceResult = await Promise.race([inProgressAsync, actionAsync]);
          if (raceResult === 'RACE') {
            this.inProgress = true;
          }
          actionCompletedSuccessFully = await actionAsync;
          cursorHandler.setLoading(false);
        } catch (error) {
          cursorHandler.setLoading(false);
          this.logger.error({ title: 'Button action error', error, buttonName: this.action.name?.replace(/^bound\s+/i, ''), props: this.$data });
        }
      }
      this.inProgress = false;
      if (actionCompletedSuccessFully) {
        this.completed = true;
        setTimeout(() => { this.completed = false; }, 1000);
      }
    },

    sendEvent() {
      const category = this.$route.name;
      let action;
      if (this.event) {
        action = this.event;
      } else if (this.action && this.action.name && this.action.name !== 'anonymous' && this.action.name !== 'action') {
        action = this.action.name.replace('bound ', '');
      } else {
        return;
      }
      fullStory.event(action, { event_category_str: category });
    }
  }
};
</script>
