import { loadStripe } from '@stripe/stripe-js/pure';
loadStripe.setLoadParameters({ advancedFraudSignals: false });

import store from '../store';

const serviceUrl = 'https://accounts.rhosys.ch';

const product = 'AUTHRESS';
const plans = {
  LOCKED: 'LOCKED',
  BETA_PLAN: 'BETA_PLAN',
  PROMOTER: 'PROMOTER',
  ENTERPRISE: 'ENTERPRISE'
};

export default class BillingClient {
  constructor(logger, platformClient) {
    this.logger = logger;
    this.platformClient = platformClient;
    this.plans = plans;

    this.fetchAccountAsync = null;
  }

  async loadBillingClient(publicEncryptionKey) {
    await loadStripe(publicEncryptionKey);
  }

  async createAccount(authressAccountId, email) {
    try {
      const url = new URL(`${serviceUrl}/v1/accounts`);
      const createResponse = await this.platformClient.post(url, { email, accountId: `authress|${authressAccountId}` });
      store.commit('setBillingAccount', createResponse.data);
      return createResponse.data;
    } catch (createError) {
      this.logger.warn({ title: 'Error creating new account', exception: createError });
      throw createError;
    }
  }

  async getAccount(accountId) {
    if (!accountId) {
      return null;
    }
    const billingAccountId = encodeURIComponent(`authress|${accountId}`);
    const url = new URL(`${serviceUrl}/v1/accounts/${billingAccountId}`);
    try {
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error?.status === 404) {
        return null;
      }

      this.logger.error({ title: 'Failed to get billing account', accountId, error });
      throw error;
    }
  }

  fetchAccount(accountId, options = { requirePaymentMethod: false, forceRefresh: false }) {
    const handler = async () => {
      try {
        const billingAccount = await this.getAccount(accountId);
        store.commit('setBillingAccount', billingAccount);
      } catch (error) {
        this.logger.error({ title: 'Failed to get account', cacheAccounts: store.state.cache.accounts, error });
      }
    };

    if (this.fetchAccountAsync && !options.forceRefresh
      && (!options.requirePaymentMethod || store.state.cache?.billingAccount?.paymentAccount?.paymentMethods?.some(pm => pm.status !== 'card_declined'))) {
      return this.fetchAccountAsync;
    }

    return this.fetchAccountAsync = handler();
  }

  async addStripePaymentSource(accountId, paymentSourceId, skipRedirects, currency, options = { type: 'STRIPE' }) {
    const url = new URL(`${serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/payment-sources`);
    const data = {
      paymentSourceId,
      redirectUrl: 'https://authress.io/app/#/settings?focus=billing&linkBillingAccount=true',
      authorizationChargeCurrency: currency || 'EUR',
      paymentSourceType: options?.type
    };
    const response = await this.platformClient.put(url, data);
    if (response.data.followup?.redirectUrl && !skipRedirects) {
      window.location.assign(response.data.followup?.redirectUrl);
    }
  }

  async removeStripePaymentSource(accountId, paymentSourceId) {
    const url = new URL(`${serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/payment-sources/${paymentSourceId}`);
    const response = await this.platformClient.delete(url);
    return response.data;
  }

  async setStripePaymentSourceAsDefault(accountId, paymentSourceId) {
    const url = new URL(`${serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}`);
    const response = await this.platformClient.patch(url, {
      paymentAccount: {
        defaultPaymentMethodId: paymentSourceId
      }
    });
    return response.data;
  }

  async revalidateCard(accountId, paymentSourceId) {
    try {
      const url = new URL(`${serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/payment-sources/${encodeURIComponent(paymentSourceId)}`);
      await this.platformClient.patch(url, {});
    } catch (error) {
      this.logger.log({ title: 'Attempted to revalidate card but it still failed', accountId, paymentSourceId, error });
      throw error;
    }
  }

  async setPaymentAccount(teamIdentityUrl, accountId, selection, featureSet = plans.BETA_PLAN, currency) {
    if (!selection) {
      throw Error.create('PlanSelectionInformationMissing');
    }
    if (!Object.values(plans).includes(featureSet)) {
      throw Error.create('UnknownFeatureSet');
    }
    const url = new URL(`${serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/payment-targets`);

    const data = {
      operation: 'ADD',
      paymentTarget: {
        type: product,
        currency: currency || 'EUR',
        featureSet: featureSet,
        selection: selection,
        identityUrl: teamIdentityUrl,
        identityId: accountId
      }
    };

    const response = await this.platformClient.patch(url, data);
    return response;
  }

  getPriceForCurrency(currency) {
    return currency === 'USD' ? 0.0012 : 0.0011;
  }
}
