import axios from 'axios';
import axiosRetry from 'axios-retry';
import deepFreeze from 'deep-freeze-strict';

import store from '../store';
import logger from '../clients/logger';
import alertHandler from '../errorHandling/alertHandler';
import authManager from '../auth/authManager';
import buildInfo from '../buildInfo';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'X-Powered-By': `Authress Restrict UI; ${buildInfo.version.releaseDate}.${buildInfo.version.buildCommit}.${buildInfo.version.buildNumber}`
};

function logSuccess(logObject, response) {
  logger.debug(Object.assign({ title: 'Platform HTTP Request' }, logObject, { status: response?.status }));
}

async function logError(logObject, error, excludeRateLimit) {
  const requestUrl = error?.config?.url || error?.request?.config?.url || error?.url;
  // The error may or may not have been rewritten by or handlers, so assume the error status could be anywhere
  const errorStatus = error?.status || error?.data?.status || error?.response?.status;
  if (errorStatus === 401) {
    logger.track({ title: `Authress UI: Found 401 request, it should be impossible to get these, so this is an error: ${requestUrl}`, error, logObject });
    const resolvedToken = await authManager.getToken({ timeoutInMillis: 0 }).catch(e => e);
    authManager.trackUnauthorizedRequest({ errorStatus, requestUrl, logObject, error, resolvedToken });
  }

  if (error?.message === 'Network Error' || error.code === 'NetworkError' || error.code === 'ECONNABORTED') {
    error.originalCode = error?.code;
    error.url = requestUrl;
    error.code = 'NetworkError';
    store.commit('trackHttpError', 'NetworkError');
  }
  if (errorStatus === 429 && !excludeRateLimit) {
    store.commit('trackHttpError', 'RateLimitError');
  }
  if (store.state.cache.errorTracking.NetworkError?.count > 1) {
    alertHandler.renderAlert('Network connectivity issue', 'Authress has detected a network connectivity issue with your device. This can affect the usability of the Management Portal. If you continue to experience issues, please reach out via a Support Ticket.', 'danger', 5000);
  }

  const notFound = errorStatus === 404;
  const newError = error?.rewrittenError ? error
    : error?.response || error?.message ? {
      online: navigator.onLine,
      method: error?.config?.method || error?.request?.config?.method,
      url: requestUrl,
      data: error?.response?.data,
      status: errorStatus,
      headers: error?.response?.headers,
      message: error?.message,
      code: error?.code,
      stack: error?.stack
    }
      : (error?.message || error);

  const level = notFound ? 'debug' : 'warn';
  logger[level](Object.assign({ title: 'Platform HTTP Error' }, logObject, { exception: newError, config: error?.config }), false);
}

class PlatformClient {
  constructor() {
    const client = axios.create();

    axiosRetry(client, {
      retries: 5,
      retryCondition(error) {
        const responseStatus = error.response?.status || error.status;
        if (responseStatus === 429) {
          return false;
        }

        return axiosRetry.isNetworkOrIdempotentRequestError(error) || (axiosRetry.isRetryableError(error) && ['post', 'patch'].includes(error.config?.method?.toLowerCase()))
        || error.code === 'ECONNABORTED' || error.code === 'NetworkError'
        // Include 401 because we should never be getting these, so force a retry, it's possible there is something wrong with the API Gateway somehow. If we didn't have a token, then we should get a tokenTimeoutException, but clearly we didn't get that, and instead we actually made the request to the API and got back a failure, Either:
        // * the Login SDK is broken and isn't throwing when it should (we have an error below to catch that.)
        // * the Restrict/Billing/Login API is incorrectly returning a 401 when it should not, so actually retry (The Authorizer is having a problem)
        || responseStatus === 401 || !responseStatus;
      },
      retryDelay: axiosRetry.exponentialDelay
    });

    client.interceptors.request.use(async configAsync => {
      const config = await configAsync;

      const token = await authManager.getToken();
      config.headers = {
        Authorization: token ? `Bearer ${token}` : undefined,
        ...config.headers
      };
      if (!token) {
        logger.warn({ title: 'No token available for request, the library must always return a token or throw. A token is likely not available because an action was taken on the UI after the user token expired, and they no longer have a valid session.', method: config.method, url: config.url });
        throw Error.create({ response: { status: 401, data: { description: 'Unauthorized: No Authorization header was specified in the Authress UI platformClient.', errorCode: 'Unauthorized' } } });
      }

      if (!config.url) {
        logger.error({ title: 'Platform HTTP Error - Url must bee defined', method: config.method, url: config.url });
        throw Error.create({ response: { status: 400, data: { description: 'PlatformClient Error: "url" must be defined', errorCode: 'BadRequest' } } });
      }
      return config;
    }, error => {
      const newError = error?.response || error?.message ? {
        rewrittenError: true,
        online: navigator.onLine,
        method: error?.config?.method || error?.request?.config?.method,
        url: error?.config?.url || error?.request?.config?.url || error?.url,
        data: error?.response?.data,
        status: error?.response?.status,
        headers: error?.response?.headers,
        message: error?.message,
        code: error?.code,
        stack: error?.stack
      } : (error?.message || error);

      throw deepFreeze(newError);
    });

    client.interceptors.response.use(response => {
      try {
        store.commit('trackHttpSuccess', response.config.url);
        return deepFreeze(response);
      } catch (error) {
        logger.warn({
          title: 'failed to freeze response object',
          response: response,
          retry: error?.config?.['axios-retry'],
          exception: error,
          url: error?.config?.url || error?.url
        }, false);
        return response;
      }
    }, error => {
      // Ignore handling of the error if the interceptor already fired, retrying stacks the other interceptors which is great, except they'll fire with the last exception which we only need to log once.
      if (error.rewrittenError) {
        throw error;
      }

      const newError = error?.response && {
        url: error.config?.url || error?.request?.config?.url || error?.url,

        online: navigator.onLine,
        method: error.config?.method || error?.request?.config?.method,
        message: error.message,

        data: error.response.data,
        status: error.response.status,
        headers: error.response.headers
      } || error.message && { message: error.message, code: error.code, stack: error.stack } || error;

      if (newError.message === 'Network Error') {
        newError.originalCode = newError.code;
        newError.code = 'NetworkError';
      }

      newError.rewrittenError = true;
      throw newError;
    });

    this.client = client;
  }

  async get(url, headers, type = 'json') {
    try {
      const response = await this.client.get(url, {
        headers: Object.assign({}, defaultHeaders, headers),
        responseType: type
      });
      logSuccess({ url, headers, method: 'GET' }, response);
      return response;
    } catch (error) {
      await logError({ url, headers: headers || {}, method: 'GET' }, error);
      throw error;
    }
  }

  async delete(url, headers, type = 'json') {
    try {
      const response = await this.client.delete(url, {
        headers: Object.assign({}, defaultHeaders, headers),
        responseType: type
      });
      logSuccess({ url, headers, method: 'DELETE' }, response);
      return response;
    } catch (error) {
      await logError({ url, headers: headers || {}, method: 'DELETE' }, error);
      throw error;
    }
  }

  async post(url, data, headers, options = { excludeRateLimit: false }) {
    try {
      const response = await this.client.post(url, data, {
        headers: Object.assign({}, defaultHeaders, headers)
      });
      logSuccess({ url, headers, method: 'POST' }, response);
      return response;
    } catch (error) {
      await logError({ url, headers: headers || {}, data, method: 'POST' }, error, options?.excludeRateLimit);
      throw error;
    }
  }

  async put(url, data, headers) {
    try {
      const response = await this.client.put(url, data, {
        headers: Object.assign({}, defaultHeaders, headers)
      });
      logSuccess({ url, headers, method: 'PUT' }, response);
      return response;
    } catch (error) {
      await logError({ url, headers: headers || {}, data, method: 'PUT' }, error);
      throw error;
    }
  }

  async patch(url, data, headers) {
    try {
      const response = await this.client.patch(url, data, {
        headers: Object.assign({}, defaultHeaders, headers)
      });
      logSuccess({ url, headers, method: 'PATCH' }, response);
      return response;
    } catch (error) {
      await logError({ url, headers: headers || {}, data, method: 'PATCH' }, error);
      throw error;
    }
  }
}

export default new PlatformClient();
