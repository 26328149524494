import Vue from 'vue';

const breakpoints = { xs: false, sm: false, md: false, lg: false, xl: false };
const activeBreakpoints = Vue.observable(breakpoints);

function recalculate() {
  Vue.set(activeBreakpoints, 'xs', !!window.matchMedia('(max-width: 575.98px)').matches);
  Vue.set(activeBreakpoints, 'sm', !!window.matchMedia('(min-width: 576px) and (max-width: 767.98px)').matches);
  Vue.set(activeBreakpoints, 'md', !!window.matchMedia('(min-width: 768px) and (max-width: 991.98px)').matches);
  Vue.set(activeBreakpoints, 'lg', !!window.matchMedia('(min-width: 992px) and (max-width: 1199.98px)').matches);
  Vue.set(activeBreakpoints, 'xl', !!window.matchMedia('(min-width: 1200px)').matches);
  Vue.set(activeBreakpoints, 'xxl', !!window.matchMedia('(min-width: 1400px)').matches);
}

document.addEventListener('DOMContentLoaded', () => {
  try {
    recalculate();
  } catch (error) { /* */ }
});

window.addEventListener('resize', recalculate);

export default activeBreakpoints;
