<template>
  <div :class="pageLoader ? 'centered' : 'inline'" :style="styles" alt="loading">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="76px" height="76px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path fill="#38B3F9" stroke="#1D2F3B" stroke-miterlimit="10" d="M256.09,43.73c-66.27,0-120,53.73-120,120c0,0.01,0,0.03,0,0.04v303.86h38v0h26.09v-0.05
        c0.38,0.02,0.75,0.06,1.13,0.06c9.94,0,18-8.06,18-18c0-9.94-8.06-18-18-18c-0.38,0-0.75,0.03-1.13,0.06v-0.06h-26.09v-42.89h35.86
        v-0.07c0.47,0.04,0.94,0.07,1.42,0.07c9.94,0,18-8.06,18-18c0-9.94-8.06-18-18-18c-0.48,0-0.95,0.04-1.42,0.07v-0.07h-35.86v-61
        h66.97v-38h-66.97v-90v0c0-45.29,36.71-82,82-82s82,36.71,82,82v0v90h-67.03v38h67.03v42h-18.91c-10.49,0-19,8.51-19,19
        c0,10.49,8.51,19,19,19h18.91h22.27h15.73v-208C376.09,97.45,322.37,43.73,256.09,43.73z M319.18,364.23
        c-6.35,0-11.5-5.15-11.5-11.5c0-6.35,5.15-11.5,11.5-11.5s11.5,5.15,11.5,11.5C330.68,359.08,325.53,364.23,319.18,364.23z">
          <animate attributeName="opacity" dur="2s" values="0.5; 1; 0.5" repeatCount="indefinite" />
      </path>
    </svg>
  </div>

</template>

<script>
export default {
  name: 'SpinnerLoader',
  props: {
    pageLoader: {
      type: Boolean,
      required: false,
      default: () => false
    },
    height: {
      type: Number,
      required: false,
      default: 76
    }
  },
  computed: {
    styles() {
      return {
        height: `${this.height}px`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9999;
  }

  .inline {
    margin: auto;
    display: inline-block;
  }
</style>
