import store from '../store';
import logger from '../clients/logger';

const tokenServiceUrl = 'https://login.authress.io';
// const serviceUrl = 'http://localhost:8081';

export default class LoginClient {
  constructor(authressClient, platformClient) {
    this.authressClient = authressClient;
    this.platformClient = platformClient;

    this.domainAsync = null;
  }

  async getApplications() {
    if (!store.getters.currentAccount?.accountId) {
      return [];
    }
    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/applications`);
    try {
      const response = await this.platformClient.get(url);
      store.commit('setApplications', response.data.applications);
      store.commit('cacheTags', response.data.applications.map(g => g.tags));
      return response.data.applications;
    } catch (error) {
      logger.error({ title: 'Failed to get applications', error });
      return [];
    }
  }

  async getGlobalLoginApplication() {
    if (!store.getters.currentAccount?.accountId) {
      return null;
    }

    const accountId = store.getters.currentAccount.accountId;
    try {
      const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/global-login`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error.status !== 404) {
        logger.error({ title: 'Failed to fetch global login configuration', accountId, error });
      }
      return null;
    }
  }

  async getApplication(applicationId) {
    if (!store.getters.currentAccount?.accountId) {
      return null;
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/applications/${applicationId}`);
    try {
      const response = await this.platformClient.get(url);
      const application = response.data;
      store.commit('cacheTags', response.data?.tags);
      store.commit('setApplication', { applicationId, application });
      return application;
    } catch (error) {
      if (error.status === 404) {
        store.commit('setApplication', { applicationId });
        return null;
      }
      throw error;
    }
  }

  async createApplication(forcedApplicationId, name, redirectUrls, defaultApplicationUrl, enableAccessToToken, sessionType, saml, tags) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/applications`);
    const response = await this.platformClient.post(url, { applicationId: forcedApplicationId, name, redirectUrls, defaultApplicationUrl, enableAccessToToken, sessionType, saml, tags });
    const newApplication = response.data;
    store.commit('setApplication', { applicationId: newApplication.applicationId, application: newApplication });
    store.commit('cacheTags', tags);
    return newApplication;
  }

  async updateApplication(applicationId, name, redirectUrls, defaultApplicationUrl, enableAccessToToken, sessionType, saml, isGlobalLoginApplication, tags) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/applications/${applicationId}`);
    try {
      const response = await this.platformClient.put(url, { name, redirectUrls, defaultApplicationUrl, enableAccessToToken, sessionType, saml, isGlobalLoginApplication, tags });
      store.commit('cacheTags', tags);

      const application = response.data;
      store.commit('setApplication', { applicationId, application });

      return response.data;
    } catch (error) {
      logger.warn({ title: 'Failed to update application', error, applicationId, name, redirectUrls, defaultApplicationUrl, enableAccessToToken, sessionType, saml, isGlobalLoginApplication, tags });
      throw error;
    }
  }

  async removeApplication(applicationId) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/applications/${applicationId}`);
    try {
      await this.platformClient.delete(url);
      store.commit('setApplication', { applicationId });
    } catch (error) {
      if (error.status === 404) {
        store.commit('setApplication', { applicationId });
        return;
      }
      throw error;
    }
  }

  async testApplication(applicationId) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const userId = store.state.profile.userId;
    const url = new URL(`https://${store.getters.currentAccount?.accountId}.login.authress.io`);
    url.pathname = `/api/accounts/${accountId}/applications/${applicationId}/users/${userId}/delegation`;
    const response = await this.platformClient.post(url, {});
    return response.data;
  }

  async fetchConnections() {
    try {
      const connections = await this.getConnections();
      store.commit('setConnections', connections);
    } catch (error) {
      logger.error({ title: 'Failed to fetch connections', error });
    }
  }

  async getConnections() {
    if (!store.getters.currentAccount?.accountId) {
      return [];
    }
    const url = new URL(`${this.authressClient.serviceUrl}/v1/connections`);
    try {
      const response = await this.platformClient.get(url);
      if (!response.data.connections) {
        logger.error({ title: 'Connections was null on fetch, investigate', response });
        return [];
      }
      store.commit('cacheTags', response.data.connections?.map(g => g.tags));
      return response.data.connections;
    } catch (error) {
      if (error.status >= 400 && error.status < 500) {
        logger.warn({ title: 'Failed to get connections due to 4XX', error });
        return [];
      }
      logger.error({ title: 'Failed to get connections', error });
      return [];
    }
  }

  async getConnection(connectionId) {
    const url = new URL(`${this.authressClient.serviceUrl}/v1/connections/${connectionId}`);
    try {
      const response = await this.platformClient.get(url);
      store.commit('cacheTags', response.data?.tags);
      const connection = response.data;
      store.commit('setConnection', { connectionId, connection });
      return connection;
    } catch (error) {
      if (error.status === 403 || error.status === 404) {
        store.commit('setConnection', { connectionId });
        return null;
      }
      logger.error({ title: 'Failed to get connection', connectionId, accountId: store.getters.currentAccount?.accountId, error });
      throw error;
    }
  }

  async createConnection(connectionData) {
    const url = new URL(`${this.authressClient.serviceUrl}/v1/connections`);
    try {
      const response = await this.platformClient.post(url, connectionData);
      store.commit('setConnection', { connectionId: response.data.connectionId, connection: response.data });
      store.commit('cacheTags', connectionData?.tags);
      return response.data;
    } catch (error) {
      if (error.status === 403) {
        throw Error.create('Forbidden');
      }

      if (error.status === 409) {
        try {
          const connection = await this.getConnection(connectionData.connectionId);
          logger.log({ title: `Conflict on connection creation, falling back to fetching the existing connection - ${connectionData.connectionId}`, consistentConnection: connection });
          return connection;
        } catch (getError) {
          if (getError.status === 403 || getError.status === 404) {
            throw Error.create('Forbidden');
          }
        }
      }
      throw error;
    }
  }

  async updateConnection(connectionId, connectionData) {
    delete connectionData.createdTime;
    delete connectionData.lastUpdated;
    const url = new URL(`${this.authressClient.serviceUrl}/v1/connections/${connectionId}`);
    try {
      const response = await this.platformClient.put(url, connectionData);
      store.commit('setConnection', { connectionId, connection: response.data });
      store.commit('cacheTags', connectionData?.tags);
      return response.data;
    } catch (error) {
      if (error.status === 400) {
        logger.warn({ title: 'Failed to save updated connection due to invalid input', error, connectionId });
        throw error;
      }

      if (error.status) {
        logger.error({ title: 'Failed to save updated connection', error, connectionId });
        throw error;
      }
      logger.warn({ title: 'Failed to save updated connection due to network connection issue', error, connectionId });
      throw error;
    }
  }

  async removeConnection(connectionId) {
    const url = new URL(`${this.authressClient.serviceUrl}/v1/connections/${connectionId}`);
    try {
      await this.platformClient.delete(url);
      store.commit('setConnection', { connectionId });
    } catch (error) {
      if (error.status === 404) {
        store.commit('setConnection', { connectionId });
        return;
      }
      throw error;
    }
  }

  async createTenant(tenantId, tenantLookupIdentifier, data, connection, domains, tokenConfiguration) {
    const url = new URL(`${this.authressClient.serviceUrl}/v1/tenants`);
    const response = await this.platformClient.post(url, { tenantId, tenantLookupIdentifier, data, connection, domains, tokenConfiguration });
    store.commit('setTenant', { tenantId: tenantId, tenant: response.data });
    return response.data;
  }

  async getTenants(filter) {
    if (!store.getters.currentAccount?.accountId) {
      return [];
    }
    const url = new URL(`${this.authressClient.serviceUrl}/v1/tenants`);
    if (filter) { url.searchParams.set('filter', filter); }
    try {
      const response = await this.platformClient.get(url);
      store.commit('setTenants', response.data.tenants);
      return response.data.tenants;
    } catch (error) {
      if (error.status >= 400 && error.status < 500) {
        logger.warn({ title: 'Failed to get tenants due to 4XX', error });
        return [];
      }
      logger.error({ title: 'Failed to get Tenants', error });
      return [];
    }
  }

  async getTenant(tenantId) {
    const url = new URL(`${this.authressClient.serviceUrl}/v1/tenants/${tenantId}`);
    try {
      const response = await this.platformClient.get(url);
      store.commit('setTenant', { tenantId: tenantId, tenant: response.data });
      return response.data;
    } catch (error) {
      if (error.status !== 404) {
        logger.error({ title: 'Failed to fetch tenant', error, tenantId });
      }
      store.commit('setTenant', { tenantId });
      return null;
    }
  }

  async updateTenant(tenantId, tenantLookupIdentifier, data, connection, domains, tokenConfiguration) {
    const url = new URL(`${this.authressClient.serviceUrl}/v1/tenants/${tenantId}`);
    const response = await this.platformClient.put(url, { tenantLookupIdentifier, data, connection, domains, tokenConfiguration });
    store.commit('setTenant', { tenantId: tenantId, tenant: response.data });
    return response.data;
  }

  async removeTenant(tenantId) {
    const url = new URL(`${this.authressClient.serviceUrl}/v1/tenants/${tenantId}`);
    try {
      await this.platformClient.delete(url);
      store.commit('setTenant', { tenantId });
    } catch (error) {
      if (error.status === 404) {
        store.commit('setTenant', { tenantId });
        return;
      }
      throw error;
    }
  }

  async getCustomDomainsForAccount(accountId) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      return null;
    }

    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/domains`);
    const response = await this.platformClient.get(url);
    return response.data.domains.map(d => d.domain);
  }

  // We always return at least a single empty custom domain, we do that because many places assume that it will exist
  async getDomainInfos() {
    if (!store.getters.currentAccount?.accountId) {
      return [];
    }
    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/domains`);
    try {
      const response = await this.platformClient.get(url);
      return response.data.domains.length ? response.data.domains : [];
    } catch (error) {
      if (error.status !== 401) {
        logger.error({ title: 'Failed to get custom domain info', error }, false);
      } else {
        logger.info({ title: 'Failed to get custom domain info due to unauthorized error.', error }, false);
      }
      return [];
    }
  }

  fetchDomainInfo(options = { forceFetch: false }) {
    if (this.domainAsync && store.state.cache.customDomains?.every(d => d.status === 'COMPLETE') && !options?.forceFetch) {
      return this.domainAsync;
    }

    const handler = async () => {
      const customDomainInfos = await this.getDomainInfos();
      store.commit('setDomains', customDomainInfos.map(customDomainInfo => ({
        status: customDomainInfo.status,
        domain: customDomainInfo.domain,
        domainRecordValue: customDomainInfo.route?.value,
        domainCertificate: customDomainInfo.validation?.recordName,
        domainCertificateRecordValue: customDomainInfo.validation?.value,
        hasTxtRecord: customDomainInfo.validation?.dynamicLookupStatus === 'TXT',
        emailValidations: customDomainInfo.emailValidations || []
      })));
    };

    return this.domainAsync = handler();
  }

  async addDomain(domain) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/domains`);
    await this.platformClient.post(url, { domain });
  }

  async deleteDomain(domain) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/domains/${encodeURIComponent(domain)}`);
    try {
      await this.platformClient.delete(url);
      store.commit('removeDomain', domain);
    } catch (error) {
      if (error.status === 404) {
        store.commit('removeDomain', domain);
      }
      logger.error({ title: 'Failed to remove custom domain, take action, setting domain to null anyway so that the user can continue, but investigate.', domain, accountId });
      store.commit('removeDomain', domain);
    }
  }

  async getBranding() {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      return {};
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/configuration`);
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async saveBranding(branding) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', store: store.state });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const url = new URL(`${tokenServiceUrl}/api/accounts/${accountId}/configuration`);
    await this.platformClient.put(url, branding);
  }
}
