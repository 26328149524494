import uuid from 'uuid';

import store from '../store';

class AlertHandler {
  /**
   * @description Create an alert
   * @param {string} message - translated message to show
   * @param {string} [details] - translated extra message to show
   * @param {string} [type] - bootstrap alert type
   * @param {int} [timeoutInMs] - time on screen, 0 or -1 is forever
   * @returns void
   */
  renderAlert(message, details, type = 'info', timeoutInMs = 0, persistent = false) {
    const alert = {
      message: message,
      details: details,
      alertType: type,
      timeoutInMs: timeoutInMs,
      persistent,
      alertId: uuid.v4()
    };
    store.commit('setUiDisplayPageNotificationAlert', alert);
  }

  /**
   * @description Reset Alert
   * @param {string} [alertType] - the alert with given alertType to remove (null, to remove alerts with any alertType)
   * @returns void
   */
  removeAlert(alertType, force = true) {
    store.commit('removeAlert', { type: alertType, force });
  }

  /**
   * @description Resets a specific alert
   * @param {Object} [alert] - the alert to remove (will not remove alerts other than the one provided)
   * @returns void
   */
  removeSpecificAlert(alert) {
    store.commit('removeSpecificAlert', alert);
  }
}

export default new AlertHandler();
