import { openDB, deleteDB, unwrap } from 'idb';
import logger from '@/clients/logger';
import { DateTime } from 'luxon';
import store from '@/store';

const authressCacheDatabase = 'AuthressCacheDatabase';
const eventsTable = 'AuditTrailEvents';
const accountIdIndex = 'accountIdIndex';
const dateIndex = 'dateIndex';
const eventTypeIndex = 'eventTypeIndex';

// Potentially migrate to one of these: https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB#libraries

class AuditEventRepository {
  constructor() {
    this.databaseAsync = null;
  }

  async initialize() {
    if (this.databaseAsync) {
      return this.databaseAsync;
    }
    try {
      const authressCacheDatabaseVersion = 1;
      this.databaseAsync = openDB(authressCacheDatabase, authressCacheDatabaseVersion, {
        async upgrade(databaseNeedsUpgrading, oldVersion, newVersion, upgradeTransaction) {
          logger.info({ title: '[AuditEventRepository] Upgrade of database running.' });

          let idbStore;
          if (!oldVersion || oldVersion < 1) {
            idbStore = await databaseNeedsUpgrading.createObjectStore(eventsTable, {
              keyPath: 'eventId', unique: true
            });
            idbStore.createIndex(accountIdIndex, 'accountId', { unique: false });
            idbStore.createIndex(dateIndex, 'dateTime', { unique: false });
            idbStore.createIndex(eventTypeIndex, 'eventTypeId', { unique: false });
          } else {
            idbStore = upgradeTransaction.objectStore(eventsTable);
          }

          // Add version 2 updates here
          if (oldVersion < 2) {
            //
          }

          // Add version 3 updates here
          if (oldVersion < 3) {
            //
          }
        }
      });

      const result = await Promise.race([this.databaseAsync, new Promise(resolve => setTimeout(resolve, 3000))]);
      if (!result) {
        logger.warn({ title: '[AuditEventRepository] Failed to open the audit event database after timeout, what is going on?' });
      }
      await this.databaseAsync;
    } catch (error) {
      logger.track({ title: 'Failed to open the audit event database', error });
    }
    return this.databaseAsync.catch(error => {
      logger.log({ title: 'Failed opening audit event database', error });
      return null;
    });
  }

  async deleteTheCurrentDatabase() {
    if (!await this.initialize()) {
      return;
    }

    try {
      logger.log({ title: 'Attempting to remove the cache database' });
      unwrap(await this.databaseAsync).close();

      const deleteAsync = deleteDB(authressCacheDatabase, {
        blocked(blockedVersion, blockedEvent) {
          logger.warn({ title: 'SQLite UI cleanup blocked due to something else happening at this moment.', authressCacheDatabase, blockedVersion, blockedEvent });
        }
      });
      await Promise.race([deleteAsync, new Promise(resolve => setTimeout(resolve, 600))]);
      this.databaseAsync = null;
    } catch (error) {
      logger.error({ title: 'Failed to delete the SQLite UI AuthressCacheDatabase', error });
    }
  }

  async insertNewAuditEvents(newAuditEvents) {
    if (!await this.initialize()) {
      return;
    }
    const db = await this.databaseAsync;
    const transaction = db.transaction(eventsTable, 'readwrite');

    const transactionPromises = newAuditEvents.map(event => {
      if (!event.eventId && !event.eventHash) {
        return null;
      }
      return transaction.store.put({
        accountId: store.getters.currentAccount.accountId,
        eventId: event.eventHash,
        ...event
      });
    });

    try {
      await Promise.allSettled(transactionPromises);
      await transaction.done;
      // Trigger computed property tracking of the audit store to be updated everywhere necessary in the UI.
      store.commit('auditEventRepositoryUpdated');
    } catch (error) {
      logger.error({ title: 'Failed to insert new audit events into the sqlite db', error });
    }

    let results = [];
    try {
      results = await db.getAllFromIndex(eventsTable, dateIndex);
    } catch (error) {
      logger.track({ title: 'Failed to insertNewAuditEvents in AuditEventRepository due to unexpected error from db.', error });
      return;
    }

    const cutoff = DateTime.utc().minus({ days: 14 }).toISO();
    const resultsToDelete = results.filter(r => r.dateTime < cutoff);

    const deleteTransaction = db.transaction(eventsTable, 'readwrite');
    const deleteTransactionPromises = resultsToDelete.map(eventToDelete => {
      return deleteTransaction.store.delete(eventToDelete.eventId);
    });

    try {
      await Promise.allSettled(deleteTransactionPromises);
      await deleteTransaction.done;
    } catch (error) {
      logger.error({ title: 'Failed to clean up old events' });
    }
  }

  async getAllEvents() {
    if (!await this.initialize()) {
      return [];
    }

    const accountId = store.getters.currentAccount?.accountId;
    if (!accountId) {
      return [];
    }
    const db = await this.databaseAsync;
    try {
      const results = await db.getAllFromIndex(eventsTable, accountIdIndex, accountId);
      return results.sort((a, b) => b.dateTime.localeCompare(a.dateTime));
    } catch (error) {
      logger.track({ title: 'Failed to getAllEvents in AuditEventRepository due to unexpected error from db.', error });
      return [];
    }
  }
}

export default new AuditEventRepository();
