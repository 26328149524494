<template>
  <a tabindex="-1" :class="computedClass" @click="onClick" :href="computedHref" :target="linkTarget" referrerpolicy="same-origin" style="white-space: nowrap;">
    <slot>{{ computedHref }}</slot><span v-if="isButton"> <i class="fas fa-external-link-square-alt fa-sm" /></span>
  </a>
</template>

<script>
import fullStory from '@/clients/fullStory';

export default {
  name: 'ExternalLink',

  inject: ['logger'],

  props: {
    to: {
      required: false,
      type: String,
      default() { return null; }
    },
    href: {
      required: false,
      type: String,
      default() { return null; }
    },
    gaRef: {
      required: false,
      type: String,
      default: null
    },
    stylization: {
      require: false,
      type: String,
      default() {
        return null;
      }
    },
    onClickCallback: {
      require: false,
      type: Function,
      default() {
        return () => {};
      }
    }
  },

  created() {
    if (!this.computedHref) {
      this.logger.error({ title: 'External Link does not contain href', gaRef: this.gaRef });
    }
  },
  methods: {
    onClick() {
      fullStory.event(this.gaRef || this.computedHref, { event_category_str: this.$route.name });
      this.onClickCallback();
    }
  },
  computed: {
    computedHref() {
      return this.to || this.href;
    },
    isButton() {
      return this.stylization === 'button';
    },

    computedClass() {
      return this.isButton ? 'btn btn-sm btn-default' : '';
    },

    linkTarget() {
      if (this.computedHref.startsWith('http://') || this.computedHref.startsWith('https://') || this.computedHref.startsWith('mailto:')) {
        return '_blank';
      }
      return '_self';
    }
  }
};
</script>

<style lang="scss" scoped>
  .fa-external-link-square {
    margin-left: 0.5rem;
    padding-right: 0;
  }
</style>
