import multiguard from 'vue-router-multiguard';
import authManager from './auth/authManager';
import { DateTime } from 'luxon';
import logger from './clients/logger';

const Support = () => import(/* webpackChunkName: "support" */ './components/support/support');
const Privacy = () => import(/* webpackChunkName: "privacy" */ './components/support/privacy');
const Terms = () => import(/* webpackChunkName: "terms" */ './components/support/terms');
const Disclosure = () => import(/* webpackChunkName: "disclosure" */ './components/support/disclosure');

const JWT = () => import(/* webpackChunkName: "jwt" */ './components/admin/jwt');
const AdminUsage = () => import(/* webpackChunkName: "admin" */ './components/admin/admin');
const ApiExplorer = () => import(/* webpackChunkName: "api" */ './components/api/apiExplorer');
const AuditLogEvents = () => import(/* webpackChunkName: "audit" */ './components/audit/events');

const Login = () => import(/* webpackChunkName: "login" */ './components/onboarding/login');
const SSO = () => import(/* webpackChunkName: "sso" */ './components/onboarding/ssoLogin');
const Signup = () => import(/* webpackChunkName: "signup" */ './components/onboarding/signup');
const AcceptInvite = () => import(/* webpackChunkName: "invite" */ './components/onboarding/acceptInvite');
const Registration = () => import(/* webpackChunkName: "registration" */ './components/onboarding/registration');
const AutoUnsubscribe = () => import(/* webpackChunkName: "autoUnsubscribe" */ './components/onboarding/autoUnsubscribe');
const Manage = () => import(/* webpackChunkName: "settings" */ './components/manage/manage');
const QuickCreate = () => import(/* webpackChunkName: "quick-create" */ './components/quickCreateFlows/quickCreateFlows');
const Wordpress = () => import(/* webpackChunkName: "wordpress" */ './components/onboarding/wordpress');
const Vanish = () => import(/* webpackChunkName: "vanish" */ './components/api/vanish');
const DemoCalendar = () => import(/* webpackChunkName: "demo" */ './components/demo');
const AuthressLocal = () => import(/* webpackChunkName: "authress-local" */ './components/api/authressLocal');
const Refund = () => import(/* webpackChunkName: "refund" */ './components/billing/refund');

function supportedBrowser(to, from, next) {
  if (window.customElements) {
    next();
    return;
  }

  next({ name: 'Support', params: { unsupportedBrowser: true } });
}

function temporarilyAllowNoAccountToWorkForSupportTickets(to, from, next) {
  // To comply with Slack and AWS logins for support, they require access to the support page for links without an account.
  const bypassLogin = new URL(window.location.href).searchParams.has('bypass');
  const forceLogin = new URL(window.location.href).searchParams.has('login');

  if (!forceLogin && DateTime.utc() < DateTime.fromISO('2024-08-31')) {
    return next();
  }

  if (bypassLogin) {
    logger.log({ title: 'Support Auth Bypass set, user can create a ticket without an account' });
    return next();
  }

  return authManager.loggedInGuard(true)(to, from, next);
}

export default function routes(authressProvider) {
  const requireAccount = multiguard([authManager.loggedInGuard(true), authressProvider.requireAccount()]);
  return [
    {
      path: '/support/:unsupportedBrowser?',
      name: 'Support',
      component: Support,
      props: true,
      beforeEnter: temporarilyAllowNoAccountToWorkForSupportTickets
    },
    {
      path: '/refund',
      name: 'Billing Refund',
      component: Refund,
      props: true,
      beforeEnter: requireAccount
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms
    },
    {
      path: '/disclosure',
      name: 'Disclosure',
      component: Disclosure
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Manage,
      meta: {
        name: 'Management Console'
      },
      props: true,
      beforeEnter: requireAccount,
      alias: ['/setup', '/manage']
    },
    {
      path: '/quick-create',
      name: 'QuickCreate',
      component: QuickCreate
    },
    {
      path: '/jwt',
      name: 'JWT',
      component: JWT,
      props: true,
      beforeEnter: requireAccount
    },
    {
      path: '/admin',
      name: 'Admin',
      component: AdminUsage,
      props: true,
      beforeEnter: requireAccount
    },
    {
      path: '/api',
      name: 'ApiExplorer',
      meta: {
        name: 'API Documentation'
      },
      component: ApiExplorer,
      props: true,
      beforeEnter: multiguard([authManager.loggedInGuard(false), supportedBrowser]),
      alias: ['/get-*', '/post-*', '/patch-*', '/put-*', '/delete-*', '/overview', '/auth']
    },
    {
      path: '/vanish',
      name: 'Vanish',
      meta: {
        name: 'Vanishing Keys'
      },
      component: Vanish,
      props: true
    },

    {
      path: '/demo',
      name: 'Demo',
      meta: {
        name: 'Schedule Demo'
      },
      component: DemoCalendar,
      props: true
    },

    {
      path: '/authress-local',
      name: 'AuthressLocal',
      meta: {
        name: 'Authress Local'
      },
      component: AuthressLocal,
      props: true,
      beforeEnter: requireAccount
    },
    {
      path: '/audit',
      name: 'AuditLogEvents',
      meta: {
        name: 'Audit Trail'
      },
      component: AuditLogEvents,
      props: true,
      beforeEnter: requireAccount
    },
    {
      path: '*',
      props: true,
      redirect: '/settings'
    },

    // Onboarding
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: authManager.loggedInGuard(false)
    },
    {
      path: '/sso/:domain?',
      name: 'SSO',
      component: SSO,
      props: true,
      beforeEnter: authManager.loggedInGuard(false)
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup,
      props: true,
      beforeEnter: authManager.loggedInGuard(false)
    },
    {
      path: '/invite/:inviteId',
      name: 'AcceptInvite',
      meta: {
        name: 'Invite'
      },
      component: AcceptInvite,
      props: true,
      beforeEnter: authManager.loggedInGuard(false)
    },
    {
      path: '/registration/:inviteId?',
      name: 'Registration',
      component: Registration,
      props: true,
      beforeEnter: authManager.loggedInGuard(true)
    },
    {
      path: '/unsubscribe',
      name: 'AutoUnsubscribe',
      meta: {
        name: 'Unsubscribe'
      },
      component: AutoUnsubscribe,
      beforeEnter: authManager.loggedInGuard(true)
    },
    {
      path: '/wordpress',
      name: 'Wordpress Setup',
      component: Wordpress,
      props: true,
      beforeEnter: requireAccount
    }
  ];
}
