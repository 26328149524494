import base64url from './base64url.js';

export default {
  decode(token) {
    try {
      return token && JSON.parse(base64url.decode(token.split('.')[1]));
    } catch (error) {
      return null;
    }
  },

  decodeFull(token) {
    try {
      return token && {
        header: JSON.parse(base64url.decode(token.split('.')[0])),
        payload: JSON.parse(base64url.decode(token.split('.')[1]))
      };
    } catch (error) {
      return null;
    }
  }
};
