<template>
  <div class="pointer" :class="{ 'd-inline': inline }" v-if="value" @click.stop="() => {}">
    <template v-if="inline">
      <b-link :id="id" class="d-inline" v-clipboard:success="copyClicked" v-clipboard:copy="computedCopyValue" @click="$emit('copied')">
        <small><i class="fa fa-copy mr-2" /></small>
      </b-link>
    </template>
    <div v-else-if="button">
      <b-button :id="id" class="d-inline btn-xs float-right" v-clipboard:success="copyClicked" v-clipboard:copy="computedCopyValue" @click="$emit('copied')">
        <i class="fa fa-copy mr-2" />Copy
      </b-button>
    </div>
    <div v-else>
      <b-input-group :id="id" :size="size" v-clipboard:success="copyClicked" v-clipboard:copy="computedCopyValue" class="copy-input">
        <template #prepend v-if="slot">
          <b-input-group-text class="d-none d-sm-block">
            <slot />
          </b-input-group-text>
        </template>
        <b-form-input :value="displayedValue" disabled class="pointer" />
        <!-- <div class="input form-control pointer" readonly disabled style="height: inherit">{{ value }}</div> -->
        <template #append>
          <b-button v-clipboard:success="copyClicked" v-clipboard:copy="computedCopyValue" @click="$emit('copied')">
            <i class="fas fa-copy" />
          </b-button>
        </template>
      </b-input-group>
    </div>
    <b-tooltip :show.sync="copied" :target="id" placement="top" triggers="click" size="sm" customClass="tooltip-class">
        Copied!
      </b-tooltip>
  </div>
</template>

<script>
import uuid from 'uuid';
import { BLink } from 'bootstrap-vue';

export default {
  name: 'CopyInput',

  components: { BLink },

  props: {
    button: {
      type: Boolean,
      required: false,
      default: false
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    text: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    formatter: {
      type: Function,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default() { return 'md'; }
    }
  },

  data() {
    return {
      id: `copy-value-${uuid.v4()}`,
      copied: false
    };
  },

  computed: {
    slot() {
      return !!this.$slots.default;
    },
    displayedValue() {
      return this.text || this.value;
    },
    computedCopyValue() {
      return this.value && this.formatter ? this.formatter(this.value) : this.value;
    }
  },

  methods: {
    copyClicked() {
      this.copied = true;
      setTimeout(() => this.copied = false, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

  button {
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
  }

  .tooltip-class {
    z-index: 999999999999999;
  }

  @include media-breakpoint-up(sm) {
    .input-group-prepend + input {
      border-left-color: $border;
    }
  }
</style>
