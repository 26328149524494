import Vue from 'vue';

export default class OnCreatedComponentMixinProvider {
  getMixin(logger, initializerResolver) {
    return Vue.extend({
      async created() {
        this.routeInitializationPromiseResolver = null;
        this.routeInitializationPromise = new Promise(resolve => this.routeInitializationPromiseResolver = resolve);
        if (this.onCreated) {
          try {
            await this.onCreated();
          } catch (error) {
            logger.critical({ title: 'OnCreatedComponentMixinProvider: Error in vue component\'s onCreated function', exception: error, component: this.$options.name });
          }

          // Can enable this to track individual components being loaded if necessary
          // logger.debug({ title: 'Component loaded', component: this.$options.name || this.$options._componentTag }, false); // eslint-disable-line no-underscore-dangle
        }
        this.routeInitializationPromiseResolver();
      },
      beforeRouteEnter(to, from, next) {
        next(async vm => {
          // Wait for the route to be completed, and then resolve the initialization promise
          // The point of this code is to block loading of unnecessary additional resources from services etc...
          // until the route has finished loading.  After that we can load async whatever additional resources we want, but this is the hook to trigger that.
          await vm.routeInitializationPromise;
          initializerResolver();
        });
      }
    });
  }
}
