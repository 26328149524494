// https://posthog.com/docs/libraries/js#advanced-option---bundle-all-required-extensions
import 'posthog-js/dist/recorder';
import 'posthog-js/dist/surveys';
import 'posthog-js/dist/exception-autocapture';
import 'posthog-js/dist/tracing-headers';
import 'posthog-js/dist/web-vitals';
import posthog from 'posthog-js';

import environment from '../environment';
import logger from './logger';
import store from '../store';

if (environment === 'production') {
  posthog.init('phc_fc3NXJmYLZgUgFXm1GqPpUSGnpLeRgn2rtppFD5We8E', {
    api_host: 'https://live.authress.io',
    ui_host: 'https://eu.posthog.com',
    person_profiles: 'identified_only',
    // https://posthog.com/docs/libraries/js#advanced-option---bundle-all-required-extensions
    disable_external_dependency_loading: true,
    persistence: 'localStorage',
    disable_session_recording: true,

    session_recording: {
      maskAllInputs: true,
      maskInputFn(text, element) {
        const maskTypes = ['email', 'password'];

        if (maskTypes.indexOf(element?.attributes.type?.value) !== -1 || maskTypes.indexOf(element?.attributes.id?.value) !== -1) {
          return '•'.repeat(text.length);
        }
        // maskTextSelector below doesn't prevent inputs from displaying
        if (element?.className?.match(/fs-exclude|ph-no-capture|sensitive/)) {
          return '•'.repeat(text.length);
        }

        // If any parent has one of these classes also hide the element
        if (element.closest('.fs-exclude, .ph-no-capture, .sensitive')) {
          return '•'.repeat(text.length);
        }

        return text;
      },
      maskTextSelector: '.fs-exclude, .ph-no-capture, .sensitive'
    }
  });
}

let initializedResolver;
const initializedAsync = new Promise(resolve => initializedResolver = resolve);
const observeSession = Math.random() < 0.1;

let sessionId_str = null;
let uiVersion_str = null;

export default {
  setKeys(sessionId, uiVersion) {
    sessionId_str = sessionId;
    uiVersion_str = uiVersion;
  },

  sessionIdentify() {
    if (!observeSession && !store.state.profile.userId || store.getters.isRhosysAdmin) {
      return;
    }

    const userId = store.state.profile.userId;
    if (userId) {
      const metadata = {};
      const displayName = store.state.profile.displayName || store.state.profile.username;
      if (displayName) { metadata.displayName = displayName.split(' ')[0]; }
      posthog.startSessionRecording();
      
      // https://posthog.com/docs/getting-started/person-properties#what-is-the-difference-between-set-and-set_once
      posthog.identify(
        userId,
        Object.assign({ environment, uiVersion: uiVersion_str, userId }, metadata),
        {
          referrerData: store.state.permanentCache.referrerData || {}
        }
      );
    }

    posthog.group('environment', environment, {});
    posthog.group('ui_version', uiVersion_str, {});

    try {
      posthog.capture('Session Started', Object.assign({ sessionId: sessionId_str, uiVersion: uiVersion_str }, store.state.permanentCache.referrerData));
    } catch (error) {
      logger.debug({ title: 'Session Replay issue', error });
    }
    initializedResolver();
  },

  async setAccountId(accountId) {
    logger.debug({ title: 'AccountSetEvent', type: 'EVENT', accountId });
    await initializedAsync;
    try {
      if (accountId) {
        posthog.capture('Account Set', { accountId, $set: { accountId } });
        posthog.register({ accountId });
        posthog.group('accountId', accountId, {});
      }
    } catch (error) {
      logger.debug({ title: 'Session Replay issue', error });
    }
  },

  async event(category, data) {
    logger.debug({ title: 'GenericEvent', type: 'EVENT', category, event: data });
    await initializedAsync;
    try {
      posthog.capture(category, data || {});
    } catch (error) {
      logger.debug({ title: 'Session Replay issue', error });
    }
  }
};

