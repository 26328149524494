<template>
  <transition nam="slide-fade">
    <div class="toaster-wrapper">
      <div v-if="showWarningForMissingUiAccess" role="alert" aria-live="assertive" aria-atomic="true" class="b-toast b-toast-solid my-4" key="transition-key">
        <div class="toast info-toast">
          <header class="toast-header bg-primary text-white">
            <strong>Welcome to the Authress management portal</strong>
          </header>

          <div v-if="sentRequestForPortalAccess">
            <div class="toast-body">
              <div class="mt-3 d-flex justify-content-center align-items-center">
                <span class="fa-stack fa-2x d-flex justify-content-center align-items-center text-success">
                  <i class="far fa-circle fa-stack-2x" />
                  <i class="fas fa-check fa-stack-lg" />
                </span>

                <h3 class="text-success">Access Requested</h3>
              </div>

              <br>
              An access request was created for you and was sent to your Authress account admin. Please wait until they approve the access request before attempting to access the portal.
            </div>
          </div>
          <div v-else>
            <div class="toast-body">
              Your access is limited because your admin hasn't assigned you permissions to this management portal.
              <br><br>
              To assign permissions to your user, please ask your admin to review the necessary steps in the <b-link target="blank" href="https://authress.io/knowledge-base/docs/category/admin-sso#step-2-access-to-the-authress-management-portal"><i class="fas fa-book fa-xs text-highlight" /> Knowledge Base</b-link>.
              <br><br>
              <copy-input size="sm" class="mb-2" :value="userId">User ID</copy-input>
              <copy-input v-if="accountId" size="sm" class="mb-2" :value="accountId">Account ID</copy-input>

              <div>
                <words>
                  Or you can directly request access here. Requesting access will send an email to your Authress account admin indicating the access you require.
                  <br>
                  Which portal permissions do you need?
                </words>

                <div class="d-flex justify-content-center">
                  <div class="ml-5 mt-3">
                    <checkbox v-model="requestedAccess.readAccount">View Account Information</checkbox>
                    <checkbox v-model="requestedAccess.readUsersAndGroups">Query Users and Groups</checkbox>
                    <checkbox v-model="requestedAccess.createAccessRecords">Create Access Records</checkbox>
                    <checkbox v-model="requestedAccess.readPlatformExtensions">Review Platform Extensions</checkbox>
                    <checkbox v-model="requestedAccess.fullAuthressAccountAdminAccess">Full Authress Admin Access</checkbox>

                    <br>

                    <activity-button :action="() => requestAccess()">Request Access</activity-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <slot v-else class="ui-access-wrapper" />
    </div>
  </transition>
</template>

<script>
import { allAuthressManagementResourcesList } from '@/clients/authressClient';
import logger from '../../clients/logger';
import Checkbox from '../shared/checkbox';

export default {
  name: 'MissingUIAccess',

  components: { Checkbox },

  inject: ['authressProvider', 'authressClient'],

  data() {
    return {
      loading: true,
      hasAccess: false,
      sentRequestForPortalAccess: false,
      requestedAccess: {
        readAccount: true,
        createAccessRecords: true,
        readUsersAndGroups: true,
        readPlatformExtensions: false,
        fullAuthressAccountAdminAccess: false
      }
    };
  },

  computed: {
    showWarningForMissingUiAccess() {
      return !this.$store.getters.hasAccessToUI && !this.loading;
    },
    userId() {
      return this.$store.state.profile?.userId;
    },
    accountId() {
      return this.$store.getters.currentAccount?.accountId;
    }
  },

  methods: {
    onCreated() {
      this.loading = false;
    },
    async requestAccess() {
      const accessRequest = {
        access: {
          users: [{ userId: this.userId }],
          statements: []
        }
      };

      if (this.requestedAccess.readAccount) {
        accessRequest.access.statements.push({ roles: ['Authress:ReadResource'], resources: [{ resourceUri: 'Authress:Configuration/Account' }] });
      }
      if (this.requestedAccess.createAccessRecords) {
        accessRequest.access.statements.push({ roles: ['Authress:AccessRecordsCreator'], resources: [{ resourceUri: '*' }] });
        accessRequest.access.statements.push({ roles: ['Authress:ReadResource'], resources: [{ resourceUri: 'Authress:Roles' }] });
      }
      if (this.requestedAccess.readUsersAndGroups) {
        accessRequest.access.statements.push({ roles: ['Authress:ReadUsers'], resources: [{ resourceUri: 'Authress:Users' }] });
        accessRequest.access.statements.push({ roles: ['Authress:ReadResource'], resources: [{ resourceUri: 'Authress:Groups' }] });
      }
      if (this.requestedAccess.readPlatformExtensions) {
        accessRequest.access.statements.push({ roles: ['Authress:ReadResource'], resources: [{ resourceUri: 'Authress:Extensions' }] });
      }
      if (this.requestedAccess.fullAuthressAccountAdminAccess) {
        accessRequest.access.statements.push({ roles: ['Authress:Owner'], resources: allAuthressManagementResourcesList });
      }
      try {
        await this.authressClient.createRequest(accessRequest);
        this.sentRequestForPortalAccess = true;
      } catch (error) {
        logger.error({ title: 'Failed to create access request', error, accessRequest });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.toaster-wrapper {
  width: 100%;

  .b-toast {
    margin: auto;
    z-index: 999;
    max-width: 90vw !important;

    @include media-breakpoint-up(md) {
      width: 48%;
    }
  }
}
</style>
