<template>
  <div class="checkbox__container">
    <input @change="toggle" type="checkbox" class="checkbox__native-box" :id="`checkbox-${id}`">
    <label :for="`checkbox-${id}`" class="checkbox__label d-flex align-items-center">
      <div class="checkbox__box d-flex align-items-center justify-content-center" :class="{ 'checkbox__box--selected': toggled }">
        <svg width="13" height="9" fill="none" class="checkbox__check" :class="{ 'checkbox__check--selected': toggled }">
          <path d="M11.4 1L4.25 8.2L1 4.92727" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="ml-2">
        <slot />
      </div>
    </label>
  </div>
</template>

<script>
import uuid from 'uuid';

export default {
  name: 'CheckboxComponent',

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      id: uuid.v4(),
      toggled: false
    };
  },

  watch: {
    value: {
      handler(newValue) {
        this.toggled = newValue;
      },
      immediate: true
    }
  },

  methods: {
    toggle() {
      this.toggled = !this.toggled;
      this.$emit('input', this.toggled);
    }
  }
};
</script>

<style lang="scss">
.checkbox__container {
  .checkbox__native-box {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .checkbox__label {
    margin: 0;
    cursor: pointer;

    .checkbox__box {
      height: 1rem;
      width: 1rem;
      min-width: 1rem;
      border-radius: 2px;
      background-color: white;
      border: 1px solid $border;

      transition: background-color 0.15s ease-out;

      &.checkbox__box--selected {
        border: none;
        background-color: $primary;
      }
    }

    .checkbox__check {
      margin-left: 1px;
      opacity: 0;
      transition: opacity 0.15s ease-out;
      &.checkbox__check--selected {
        opacity: 1;
      }
    }
  }
}
</style>
