import { DateTime } from 'luxon';
import shortUuid from 'short-uuid';
import store from '../store';
import logger from '../clients/logger';
import { cloneDeep } from 'lodash';

// To test a local Authress open specification update, swap the default region to be 'local' (make sure the service is running on localhost:8081 as well)
const defaultRegion = 'eu-west';
// const defaultRegion = 'local';
// Set for Testing only
const overrideUrl = ''; // https://tst-api.authress.io/wparad-add-request-controller';

const managedRoles = [
  { name: 'Viewer', roleId: 'Authress:ReadResource', description: 'Read Resource',
    permissions: ['read'], types: [{ allow: true }], deprecated: true },
  { name: 'Editor', roleId: 'Authress:Editor', description: 'Read and Edit Resource',
    permissions: ['read', 'update'], types: [{ allow: true }, { allow: true }], deprecated: true },
  { name: 'Supervisor', roleId: 'Authress:Supervisor', description: 'Manage Resource',
    permissions: ['*', 'read', 'update'], types: [{ allow: true }, { allow: true, grant: true }, { allow: true, grant: true }], deprecated: true },
  { name: 'Security Admin', roleId: 'Authress:SecurityAdmin', description: 'Assign Resource Permissions',
    permissions: ['*'], types: [{ allow: false, grant: false, delegate: true }], deprecated: true },
  { name: 'Owner', roleId: 'Authress:Owner', description: 'Star (*) Allow & Delegate',
    permissions: ['*'], types: [{ allow: true, grant: true, delegate: true }] },
  { name: 'Access Records Creator', roleId: 'Authress:AccessRecordsCreator', description: 'Create Authress access records (individual permissions are still required)',
    permissions: ['access-records:create'], types: [{ allow: true }] },
  { name: 'Read Users', roleId: 'Authress:ReadUsers', description: 'Fetch user data from the Users API.',
    permissions: ['users:read'], types: [{ allow: true }] },
  { name: 'User Credentials Viewer', roleId: 'Authress:UserCredentialsViewer', description: "Read credentials from an OAuth connection's credential vault",
    permissions: ['connections:credentials:read'], types: [{ allow: true }] },
  { name: 'Authenticate User', roleId: 'Authress:AuthenticateUser', description: 'Allow directly authenticating a user using their password',
    permissions: ['connections:authenticate-user'], types: [{ allow: true }] },
  { name: 'Extension Developer', roleId: 'Authress:ExtensionDeveloper', description: 'Manage a platform extension',
    permissions: ['extensions:read', 'extensions:update'], types: [{ allow: true, grant: true }, { allow: true, grant: true }] },
  { name: 'Extension Viewer', roleId: 'Authress:ExtensionViewer', description: 'View a platform extension',
    permissions: ['extensions:read'], types: [{ allow: true }] }
].sort((a, b) => a.name.localeCompare(b.name));

export const allAuthressManagementResourcesList = [
  { resourceUri: 'Authress:*' },
  { resourceUri: 'Authress:Applications' },
  { resourceUri: 'Authress:Connections' },
  { resourceUri: 'Authress:Configuration' },
  { resourceUri: 'Authress:AccountBilling' },
  { resourceUri: 'Authress:ServiceClients' },
  { resourceUri: 'Authress:AccessRecords' },
  { resourceUri: 'Authress:AccessRequests' },
  { resourceUri: 'Authress:ResourcePermissions' },
  { resourceUri: 'Authress:UserPermissions' },
  { resourceUri: 'Authress:Roles' },
  { resourceUri: 'Authress:Users' },
  { resourceUri: 'Authress:Groups' }
];

export const availableRegionMap = {
  'eu-west': {
    schema: 'https',
    rootDomain: 'api-eu-west.authress.io'
  },
  'na-west': {
    schema: 'https',
    rootDomain: 'api-na-west.authress.io'
  },
  'na-east': {
    schema: 'https',
    rootDomain: 'api-na-east.authress.io'
  },
  'ap-east': {
    schema: 'https',
    rootDomain: 'api-ap-east.authress.io'
  },
  'eu-central': {
    schema: 'https',
    rootDomain: 'api-eu-central.authress.io'
  }
};

const regionConfigMap = Object.assign({ local: { schema: 'http', rootDomain: 'localhost:8081/api' } }, cloneDeep(availableRegionMap));

export default class AuthressClient {
  constructor(platformClient) {
    this.serviceUrl = `${regionConfigMap[defaultRegion].schema}://${regionConfigMap[defaultRegion].rootDomain}`;
    this.regionalServiceUrl = `${regionConfigMap[defaultRegion].schema}://${regionConfigMap[defaultRegion].rootDomain}`;

    this.platformClient = platformClient;
  }

  /* Get Account is the only one that case use the global fetching, besides invites, otherwise they need to be region specific */
  getAccounts(lastLogout) {
    const filteredRegions = defaultRegion === 'local' ? ['local'] : Object.keys(regionConfigMap).filter(r => r !== 'local');
    return filteredRegions.map(async region => {
      const url = new URL(`${regionConfigMap[region].schema}://${regionConfigMap[region].rootDomain}/v1/accounts`);
      if (lastLogout) {
        url.searchParams.set('earliestCacheTime', lastLogout);
      }
      try {
        const response = await this.platformClient.get(url);
        return response.data.accounts;
      } catch (error) {
        if (error.code === 'NetworkError' || error.status === 401) {
          logger.warn({ title: 'Failed to fetch accounts from region due to network issue', region, error });
          return [];
        }
        logger.error({ title: 'Failed to fetch accounts from region', region, error });
        return [];
      }
    });
  }

  /** **************************************************************************************************************************/

  async createAccount(accountData, acceptTermsAndConditions = true) {
    const region = regionConfigMap[accountData.region || defaultRegion];
    const url = new URL(`${region.schema}://${region.rootDomain}/v1/accounts`);
    const response = await this.platformClient.post(url, { ...accountData, acceptTermsAndConditions });
    return response.data;
  }

  async updateAccount(accountId, accountData) {
    const url = new URL(`${this.serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}`);
    const response = await this.platformClient.put(url, accountData);
    return response.data;
  }

  async updateAccountSsoConfiguration(accountId, accountData) {
    const url = new URL(`${this.serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/sso`);
    const response = await this.platformClient.put(url, accountData);
    return response.data;
  }

  async getAccount(accountId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      logger.debug({ title: 'Falling back to regional anonymous domain due to error with account domain', error, accountId });
    }
    const url = new URL(`${this.regionalServiceUrl}/v1/accounts/${encodeURIComponent(accountId)}`);
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async getAccountUsage(accountId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/usage`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error.status === 401 || error.status === 403 || error.status === 404) {
        return {};
      }
      logger.debug({ title: 'Falling back to regional anonymous domain due to error with account domain', error, accountId });
    }

    const url = new URL(`${this.regionalServiceUrl}/v1/accounts/${encodeURIComponent(accountId)}/usage`);
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async getAccountInvoices() {
    const accountId = store.getters.currentAccount?.accountId;
    if (!accountId) {
      return [];
    }
    try {
      const url = new URL(`${this.serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/invoices`);
      const response = await this.platformClient.get(url);
      return response.data.invoices;
    } catch (error) {
      if (error.status === 401 || error.status === 403 || error.status === 404) {
        return [];
      }
      logger.error({ title: 'Failed to get invoices for account', error, accountId });
      return [];
    }
  }

  async getLimitedAccountUsage(account) {
    try {
      const url = new URL(`${account.webHostFqdn || account.apiHostFqdn}/v1/accounts/${encodeURIComponent(account.accountId)}/usage`);
      url.searchParams.set('excludePlanUsage', true);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      return {};
    }
  }

  async requestLimitIncrease(accountId, type = 'TIER', limit, tier) {
    const request = { type: type || 'TIER', limit, tier };
    try {
      const url = new URL(`${this.serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}/usage`);
      await this.platformClient.patch(url, request);
      return;
    } catch (error) {
      if (error.status === 401 || error.status === 403 || error.status === 404) {
        return;
      }
      logger.debug({ title: 'Falling back to regional anonymous domain due to error with account domain', error, accountId });
    }

    try {
      const url = new URL(`${this.regionalServiceUrl}/v1/accounts/${encodeURIComponent(accountId)}/usage`);
      await this.platformClient.patch(url, request);
    } catch (error) {
      logger.error({ title: 'Failed to request limit increase', serviceUrl: this.serviceUrl, accountId, type, limit, error });
    }
  }

  setDomain(rawDomain, regionKey) {
    const currentServiceUrl = new URL(this.serviceUrl);
    const regionalServiceUrl = new URL(this.regionalServiceUrl);

    const fallbackRegionMap = {
      'na-east-1': 'na-east',
      'na-west-1': 'na-west',
      'eu-west-1': 'eu-west',
      'ap-southeast-1': 'ap-east',
      'eu-central-2': 'eu-central'
    };
    const region = (defaultRegion === 'local' ? regionConfigMap[defaultRegion] : regionConfigMap[regionKey || defaultRegion]) || regionConfigMap[fallbackRegionMap[regionKey]];

    currentServiceUrl.schema = region.schema || 'https';

    // Prevent issues with cloudfront when the domain is acc_, there are two accounts left that still have this format
    const domain = rawDomain?.replace('acc_', 'acc-');
    currentServiceUrl.host = domain ? `${domain}.${region.rootDomain}` : `${region.rootDomain}`;
    const newServiceUrl = currentServiceUrl.toString();
    if (overrideUrl) {
      this.serviceUrl = overrideUrl;
      return;
    }
    this.serviceUrl = currentServiceUrl.pathname === '/' ? newServiceUrl.slice(0, -1) : newServiceUrl;

    regionalServiceUrl.schema = region.schema || 'https';
    regionalServiceUrl.host = region.rootDomain;
    const newRegionalServiceUrl = regionalServiceUrl.toString();
    this.regionalServiceUrl = regionalServiceUrl.pathname === '/' ? newRegionalServiceUrl.slice(0, -1) : newRegionalServiceUrl;
  }

  getDocumentationUrl() {
    if (this.serviceUrl.match(/(localhost|tst-api(-\w*)?.authress.io)/)) {
      return `${this.serviceUrl}/.well-known/openapi`;
    }

    return 'https://api.authress.io/.well-known/openapi';
  }

  async registerExternalIdentityProvider(jwt, preferredAudience, issuer, userIdExpression) {
    const url = new URL(`${this.serviceUrl}/v1/identities`);
    const requestBody = {
      preferredAudience
    };
    if (jwt) { requestBody.jwt = jwt; }
    if (issuer) { requestBody.issuer = issuer; }
    if (userIdExpression) { requestBody.userIdExpression = userIdExpression; }

    await this.platformClient.post(url, requestBody);
  }

  async getLinkedIdentities() {
    try {
      const url = new URL(`${this.serviceUrl}/v1/identities`);
      const response = await this.platformClient.get(url);
      return response.data.identities;
    } catch (error) {
      if (error.status !== 404 && error.status !== 403) {
        logger.error({ title: 'Failed to get linked identities', error, serviceUrl: this.serviceUrl });
        return [];
      }

      logger.log({ title: 'Failed to get linked identities', error, serviceUrl: this.serviceUrl });
      return [];
    }
  }

  async createClient(clientData) {
    const url = new URL(`${this.serviceUrl}/v1/clients`);
    const response = await this.platformClient.post(url, clientData);
    return response.data;
  }
  async getClients() {
    const url = new URL(`${this.serviceUrl}/v1/clients`);
    const response = await this.platformClient.get(url);
    return response.data;
  }
  async getClient(clientId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/clients/${encodeURIComponent(clientId)}`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error.status === 404) {
        return null;
      }
      throw error;
    }
  }
  async updateClient(clientId, clientData) {
    const url = new URL(`${this.serviceUrl}/v1/clients/${encodeURIComponent(clientId)}`);
    await this.platformClient.put(url, clientData);
  }
  async removeClient(clientId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/clients/${encodeURIComponent(clientId)}`);
      await this.platformClient.delete(url);
    } catch (error) {
      if (error.status === 404) {
        return;
      }
      throw error;
    }
  }
  async addClientKey(clientId) {
    const url = new URL(`${this.serviceUrl}/v1/clients/${encodeURIComponent(clientId)}/access-keys`);
    const response = await this.platformClient.post(url, null, null, { excludeRateLimit: false });
    return response.data;
  }
  async removeClientKey(clientId, keyId) {
    if (!keyId) {
      return;
    }
    const url = new URL(`${this.serviceUrl}/v1/clients/${encodeURIComponent(clientId)}/access-keys/${encodeURIComponent(keyId)}`);
    await this.platformClient.delete(url);
  }

  async createAccessRecord(record) {
    const url = new URL(`${this.serviceUrl}/v1/records`);
    await this.platformClient.post(url, record);
  }

  async createRequest(requestBody) {
    const url = new URL(`${this.serviceUrl}/v1/requests`);
    await this.platformClient.post(url, requestBody);
  }

  async getRequest(requestId) {
    const url = new URL(`${this.serviceUrl}/v1/requests/${requestId}`);
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async getRequests() {
    const url = new URL(`${this.serviceUrl}/v1/requests`);
    url.searchParams.append('status', 'OPEN');
    const response = await this.platformClient.get(url);
    return response.data.requests;
  }

  async patchRequest(requestId, status) {
    const url = new URL(`${this.serviceUrl}/v1/requests/${requestId}`);
    await this.platformClient.patch(url, { status });
  }

  async getUser(userId, options = { ignorePermissionErrors: false }) {
    const url = new URL(`${this.serviceUrl}/v1/users/${encodeURIComponent(userId)}`);
    try {
      const response = await this.platformClient.get(url);
      store.commit('setErrorTracking', { errorType: 'ssoConfigurationError' });
      store.commit('addUserData', { userId, user: response.data });
      return response.data;
    } catch (error) {
      if (error.status === 400 && error.data?.errorCode === 'ProviderError' || error.data.errorCode === 'AccessDenied') {
        if (!options?.ignorePermissionErrors) {
          store.commit('setErrorTracking', { errorType: 'ssoConfigurationError', data: error.data.errorCode === 'AccessDenied' && error.data.title || error.data });
        }
        store.commit('addUserData', { userId, user: null });
        return null;
      }
      if (error.status === 400 && error.data.errorCode === 'InvalidRequest') {
        store.commit('addUserData', { userId, user: null });
        logger.warn({ title: 'Failed to look up user rejected due to an issue with the http request.', error, userId });
        return null;
      }
      if (error.status === 400) {
        store.commit('addUserData', { userId, user: null });
        logger.error({ title: 'Failed to look up user due to other provider proxy configuration issue', error, userId });
        return null;
      }
      if (error.status === 401 || error.status === 403 || error.status === 404) {
        store.commit('addUserData', { userId, user: null });
        return null;
      }
      throw error;
    }
  }

  async getUserSessions(userId) {
    const accountId = store.getters.currentAccount?.accountId;
    const url = new URL(`https://login.authress.io/api/accounts/${accountId}/users/${encodeURIComponent(userId)}/sessions`);
    try {
      const response = await this.platformClient.get(url);
      return response.data.sessions || [];
    } catch (error) {
      logger.warn({ title: 'Failed to look up user sessions', error, userId });
      if (error.status === 400 && error.data?.errorCode === 'ProviderError' || error.data.errorCode === 'AccessDenied') {
        store.commit('setErrorTracking', { errorType: 'ssoConfigurationError', data: error.data.errorCode === 'AccessDenied' && error.data.title || error.data });
        return [];
      }
      if (error.status === 401 || error.status === 403 || error.status === 404) {
        return [];
      }
      logger.error({ title: 'Failed to look up user sessions', error, userId });
      return [];
    }
  }

  async searchForUsers(text) {
    const accountId = store.getters.currentAccount?.accountId;
    const url = new URL(`${this.serviceUrl}/v1/users`);
    if (text?.length) {
      url.searchParams.set('filter', text?.length > 2 && text || '');
    }
    try {
      const response = await this.platformClient.get(url);
      store.commit('setErrorTracking', { errorType: 'ssoConfigurationError' });
      const users = response.data.users || [];

      users.forEach(u => {
        store.commit('addUserData', { userId: u.userId, user: u });
      });

      return { users, next: response.data.pagination?.next || response.data.links?.next };
    } catch (error) {
      if (error.status === 400 || error.data?.errorCode === 'AccessDenied') {
        store.commit('setErrorTracking', { errorType: 'ssoConfigurationError', data: error.data.errorCode === 'AccessDenied' && error.data.title || error.data });
        return { users: [] };
      }
      if (error.status === 401 || error.status === 403 || error.status === 404) {
        return { users: [] };
      }
      if (error.status === 503) {
        logger.warn({ title: 'Failed to search for users because there is an issue with the proxy provider', text, error });
        throw Error.create(error.data?.title || error.data || 'Unspecified API Request Error', 'UserManagementProxyHasAProblem');
      }
      logger.track({ title: 'Failed to search for users', error, searchText: text, accountId, serviceUrl: this.serviceUrl });
      throw error;
    }
  }

  async getUserPermissionsForResource(userId, resourceUri) {
    const url = new URL(`${this.serviceUrl}/v1/users/${encodeURIComponent(userId)}/resources/${encodeURIComponent(resourceUri)}/permissions/`);

    const response = await this.platformClient.get(url);
    return response.data.permissions;
  }

  async getUserRolesForResource(userId, resourceUri) {
    const url = new URL(`${this.serviceUrl}/v1/users/${encodeURIComponent(userId)}/resources/${encodeURIComponent(resourceUri)}/roles/`);

    const response = await this.platformClient.get(url);
    return response.data.roles;
  }

  async getResourcesForUser(userId, resourceUri) {
    const url = new URL(`${this.serviceUrl}/v1/users/${encodeURIComponent(userId)}/resources?resourceUri=${encodeURIComponent(resourceUri)}&collectionConfiguration=INCLUDE_NESTED`);

    const response = await this.platformClient.get(url);
    return response.data.resources;
  }

  async validateUserAuthorization(userId, resourceUri, permission, explicitCheck) {
    const url = new URL(`${this.serviceUrl}/v1/users/${encodeURIComponent(userId)}/resources/${encodeURIComponent(resourceUri)}/permissions/${encodeURIComponent(permission)}`);

    try {
      const result = await this.platformClient.get(url);
      return result.data;
    } catch (error) {
      if (explicitCheck) {
        throw error;
      }

      if (error.status === 403) {
        return false;
      }

      if (error.status === 404) {
        if (userId?.match(/^authress/i)) {
          logger.error({ title: `How did this user even get here, if they do not even know about the account? We should not have returned this? Was it saved in their cache? This did actually happen one time so we are making this an error. If the user is logged in with an identity that does not have access to this account, we should track this. Ideally we just wipe the whole cache and send the user back to the beginning and force them to log in again - ${store.getters.currentAccount?.accountId} - ${userId}`, userId, resourceUri, permission, error });
          return false;
        }

        logger.info({ title: `The user logged in with an SSO account before they were given any permissions. Giving them the open to create an access request to get the access they want - ${store.getters.currentAccount?.accountId} - ${userId}`, userId, resourceUri, permission, error });
        return false;
      }

      throw error;
    }
  }

  getManagedRoles() {
    managedRoles.forEach(r => r.managed = true);
    return managedRoles;
  }
  async getRoleListResponse() {
    const url = new URL(`${this.serviceUrl}/v1/roles`);
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async createRole(roleId, roleData) {
    const url = new URL(`${this.serviceUrl}/v1/roles`);
    const result = await this.platformClient.post(url, roleData);
    return result.data;
  }

  async putRole(roleId, roleData) {
    const url = new URL(`${this.serviceUrl}/v1/roles/${encodeURIComponent(roleId)}`);
    const result = await this.platformClient.put(url, roleData);
    return result.data;
  }

  async deleteRole(roleId) {
    const url = new URL(`${this.serviceUrl}/v1/roles/${encodeURIComponent(roleId)}`);
    try {
      await this.platformClient.delete(url);
    } catch (error) {
      if (error.status === 404) {
        return;
      }
      throw error;
    }
  }

  async getRecord(recordId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/records/${encodeURIComponent(recordId)}`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error.status === 404) {
        return null;
      }
      throw error;
    }
  }

  async getRecords(filter) {
    const url = new URL(`${this.serviceUrl}/v1/records`);
    if (filter) {
      url.searchParams.append('filter', filter);
    }
    url.searchParams.append('status', 'ACTIVE');
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async putRecord(recordId, recordData, lastUpdated) {
    recordData.statements = recordData.statements.filter(s => s.roles.length && s.resources.length);
    recordData.users = recordData.users.filter(u => u.userId?.trim());
    recordData.admins = recordData.admins.filter(u => u.userId?.trim());
    const headers = lastUpdated && {
      'If-Unmodified-Since': DateTime.fromISO(lastUpdated).toISO()
    };
    const url = new URL(`${this.serviceUrl}/v1/records/${encodeURIComponent(recordId)}`);
    await this.platformClient.put(url, recordData, headers);
  }

  async createRecord(recordData) {
    recordData.statements = recordData.statements.filter(s => s.roles.length && s.resources.length);
    recordData.users = recordData.users.filter(u => u.userId?.trim());
    recordData.admins = (recordData.admins || []).filter(u => u.userId?.trim());
    const url = new URL(`${this.serviceUrl}/v1/records`);
    const response = await this.platformClient.post(url, recordData);
    return response.data;
  }

  async deleteRecord(recordId) {
    const url = new URL(`${this.serviceUrl}/v1/records/${encodeURIComponent(recordId)}`);
    try {
      await this.platformClient.delete(url);
    } catch (error) {
      if (error.status === 404) {
        return;
      }
      throw error;
    }
  }

  /* GROUPS */
  async getGroup(groupId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/groups/${encodeURIComponent(groupId)}`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error.status === 404) {
        return null;
      }
      throw error;
    }
  }

  async getGroups(filter) {
    const url = new URL(`${this.serviceUrl}/v1/groups`);
    if (filter) {
      url.searchParams.append('filter', filter);
    }
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async putGroup(groupId, groupData) {
    groupData.users = groupData.users.filter(u => u.userId?.trim());
    groupData.admins = groupData.admins.filter(u => u.userId?.trim());
    const url = new URL(`${this.serviceUrl}/v1/groups/${encodeURIComponent(groupId)}`);
    await this.platformClient.put(url, groupData);
  }

  async createGroup(groupData) {
    groupData.users = groupData.users.filter(u => u.userId?.trim());
    groupData.admins = groupData.admins.filter(u => u.userId?.trim());
    const url = new URL(`${this.serviceUrl}/v1/groups`);
    const response = await this.platformClient.post(url, groupData);
    return response.data;
  }

  async deleteGroup(groupId) {
    const url = new URL(`${this.serviceUrl}/v1/groups/${encodeURIComponent(groupId)}`);
    try {
      await this.platformClient.delete(url);
    } catch (error) {
      if (error.status === 404) {
        return;
      }
      throw error;
    }
  }
  /* ********************************************* */
  /* *************** ALERTS ********************** */
  /* ********************************************* */

  async getAlert(alertId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/alerts/${encodeURIComponent(alertId)}`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error.status === 404) {
        return null;
      }
      throw error;
    }
  }

  async getAlerts(filter) {
    const url = new URL(`${this.serviceUrl}/v1/alerts`);
    if (filter) {
      url.searchParams.append('filter', filter);
    }
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async putAlert(alertId, alertData) {
    const url = new URL(`${this.serviceUrl}/v1/alerts/${encodeURIComponent(alertId)}`);
    await this.platformClient.put(url, alertData);
  }

  async createAlert(alertData) {
    const url = new URL(`${this.serviceUrl}/v1/alerts`);
    const response = await this.platformClient.post(url, alertData);
    return response.data;
  }

  async deleteAlert(alertId) {
    const url = new URL(`${this.serviceUrl}/v1/alerts/${encodeURIComponent(alertId)}`);
    try {
      await this.platformClient.delete(url);
    } catch (error) {
      if (error.status === 404) {
        return;
      }
      throw error;
    }
  }
  /* ********************************************* */

  async getAccountEvents() {
    const url = new URL(`${this.serviceUrl}/v1/analytics/authorization-events`);
    try {
      const response = await this.platformClient.get(url);
      return response.data.requests || [];
    } catch (error) {
      if (error.code === 'NetworkError') {
        throw Error.create('BrowserBlockedRequest');
      }
      if (error.status === 403 || error.status === 404) {
        logger.warn({ title: 'User does not have access analytics events', error });
        return [];
      }
      logger.track({ title: 'Failed to get analytic events', error, serviceUrl: this.serviceUrl });
      return [];
    }
  }

  async createInvite(email, statements, tenantId) {
    const url = new URL(`${this.serviceUrl}/v1/invites`);
    const result = await this.platformClient.post(url, { email, statements, tenantId });
    return result.data;
  }

  // TODO: invites are multi-regional so we don't actually need to loop over all the regions to do the accept, Instead we need to:
  // 1. change this code to be sequential
  // 2. change Restrict Service to do the correct thing in every region (accept the invite even if the account is in another region AND return that correct region in the response.)
  // 3. Update this code to only perform the request in one region (doesn't matter which one.)
  async acceptInvite(inviteId) {
    const filteredRegions = defaultRegion === 'local' ? ['local'] : Object.keys(regionConfigMap).filter(r => r !== 'local');
    const acceptedInvites = await Promise.all(filteredRegions.map(async region => {
      const url = new URL(`${regionConfigMap[region].schema}://${regionConfigMap[region].rootDomain}/v1/invites/${encodeURIComponent(inviteId)}`);
      try {
        const response = await this.platformClient.patch(url);
        return { region, account: response.data.account };
      } catch (error) {
        if (error.status === 404 || error.status === 409) {
          return null;
        }

        logger.error({ title: 'Failed to accept invite in region,', region, error });
        throw Error.create('FailedToAccessInvite');
      }
    }));

    const foundInvite = acceptedInvites.find(i => i);
    if (!foundInvite) {
      throw Error.create('InvalidInvite');
    }

    const url = new URL(`${regionConfigMap[foundInvite.region].schema}://${regionConfigMap[foundInvite.region].rootDomain}/v1/accounts/${foundInvite.account.accountId}`);
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async getPaginationData(nextUrl) {
    if (!nextUrl) {
      return {};
    }

    const nextUrlAsUrl = new URL(nextUrl);
    if (nextUrlAsUrl.hostname.match(/localhost/)) {
      const url = new URL(`${this.serviceUrl}${nextUrlAsUrl.pathname || ''}?${nextUrlAsUrl.searchParams.toString()}${nextUrlAsUrl.hash}`);
      const response = await this.platformClient.get(url);
      return response.data;
    }

    const response = await this.platformClient.get(nextUrl);
    return response.data;
  }

  /* Integrations */
  async getIntegrations() {
    try {
      const url = new URL(`${this.serviceUrl}/v1/integrations`);
      const response = await this.platformClient.get(url);
      return response.data.integrations;
    } catch (error) {
      if (error.status === 403 || error.status === 404) {
        return [];
      }
      logger.log({ title: 'Failed to get integrations', serviceUrl: this.serviceUrl, error });
      throw error;
    }
  }

  async updateIntegrations(integration) {
    if (integration) {
      const url = new URL(`${this.serviceUrl}/v1/integrations`);
      const response = await this.platformClient.post(url, integration);
      return response.data;
    }

    const integrations = await this.getIntegrations();
    if (!integrations.length) {
      return null;
    }

    const url = new URL(`${this.serviceUrl}/v1/integrations/${integrations[0].type}`);
    const response = await this.platformClient.delete(url);
    return response.data;
  }

  /** ***************************************************/
  /** **************** EXTENSIONS ***********************/
  /** ***************************************************/
  async getExtension(extensionId) {
    try {
      const url = new URL(`${this.serviceUrl}/v1/extensions/${encodeURIComponent(extensionId)}`);
      const response = await this.platformClient.get(url);
      return response.data;
    } catch (error) {
      if (error.status === 404) {
        return null;
      }
      throw error;
    }
  }

  async getExtensions(filter) {
    const url = new URL(`${this.serviceUrl}/v1/extensions`);
    if (filter) {
      url.searchParams.append('filter', filter);
    }
    const response = await this.platformClient.get(url);
    return response.data;
  }

  async putExtension(extensionId, extensionData) {
    const url = new URL(`${this.serviceUrl}/v1/extensions/${encodeURIComponent(extensionId)}`);
    await this.platformClient.put(url, extensionData);
  }

  async createExtension(extensionData) {
    const url = new URL(`${this.serviceUrl}/v1/extensions`);
    const response = await this.platformClient.post(url, extensionData);
    return response.data;
  }

  async deleteExtension(extensionId) {
    const url = new URL(`${this.serviceUrl}/v1/extensions/${encodeURIComponent(extensionId)}`);
    try {
      await this.platformClient.delete(url);
    } catch (error) {
      if (error.status === 404) {
        return;
      }
      throw error;
    }
  }

  /** ***************************************************/
  /** **************** ADMIN ****************************/
  /** ***************************************************/

  async unsubscribeFromAccountCreationFlow() {
    const accountId = store.getters.currentAccount?.accountId;
    const currentAccount = store.getters.currentAccount;
    if (!accountId) {
      logger.error({ title: 'Failed to unsubscribe user automatically from account creation flow because they are not logged in. Figure out better how to make them log in.', currentAccount });
      return null;
    }

    try {
      const url = new URL(`${this.serviceUrl}/v1/accounts/${encodeURIComponent(accountId)}`);
      const response = await this.platformClient.patch(url);
      return response.data;
    } catch (error) {
      logger.error({ title: 'Failed to unsubscribe user automatically from account creation flow', error, accountId });
    }
    return null;
  }

  async createSupportTicket(ticket) {
    const ticketId = `tkt_${shortUuid('abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ346789').generate()}`;
    const accountId = store.getters.currentAccount?.accountId;

    const ticketData = { accountId, ticketId, ticket };
    logger.log({ title: `Support ticket created - ${ticketId}`, ticketData });
    const url = new URL(`${this.serviceUrl}/v1/support-tickets`);
    try {
      const response = await this.platformClient.post(url, ticketData);
      return response.data;
    } catch (error) {
      logger.error({ title: `Failed to create support ticket: ${ticketId} - ${JSON.stringify(ticket)}`, ticketData });
    }
    return { ticketId, responseTime: 'PT24H', optimizedResponseTime: 'PT1H' };
  }

  async requestRefund(refundData) {
    logger.track({ title: 'Refund Requested', refundData });
    const ticketId = await this.createSupportTicket({ ...refundData, area: 'Refund' });
    return ticketId;
  }

  /** ***************************************************/
  /** **************** CONNECTIONS***********************/
  /** ***************************************************/

  async createConnection(connectionData) {
    const url = new URL(`${this.serviceUrl}/v1/connections`);
    try {
      const response = await this.platformClient.post(url, connectionData);
      return response.data;
    } catch (error) {
      if (error.status === 409) {
        try {
          const connection = await this.getConnection(connectionData.connectionId);
          return connection;
        } catch (getError) {
          if (getError.status === 403 || getError.status === 404) {
            throw Error.create('Forbidden');
          }
        }
      }
      throw error;
    }
  }

  async updateConnection(connectionId, issuerUrl, tokenUrl, authenticationUrl, clientId, clientSecret, providerCertificate, defaultConnectionProperties, data, type, userDataConfiguration) {
    const url = new URL(`${this.serviceUrl}/connections/${connectionId}`);
    const response = await this.platformClient.put(url, { type, issuerUrl, tokenUrl, authenticationUrl, clientId, clientSecret, providerCertificate, defaultConnectionProperties, data,
      userDataConfiguration });
    return response.data;
  }
}
