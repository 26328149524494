<template>
  <!-- Increase the line height just ever so slightly so that multi line when the text wraps looks just ever so slightly better. -->
  <span style="line-height: 1.1;">{{ newUri }}</span>
</template>

<script>
export default {
  name: 'StarAsteriskComponent',

  props: {
    uri: {
      type: String,
      required: true
    }
  },

  computed: {
    newUri() {
      // https://symbl.cc/en/2736/
      return this.uri.replace(/[*]/g, '✶');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
