<template>
  <div class="help-text text-default" :class="{ noselect: !!noSelect }" style="word-break: break-word"><slot /></div>
</template>

<script>
export default {
  name: 'WordsComponent',

  props: {
    noSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
  // .help-text {
  //   font-size: smaller !important;
  // }
</style>
