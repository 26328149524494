import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import createMultiTabState from 'vuex-multi-tab-state';

try {
  typeof window !== 'undefined' && window.localStorage.removeItem('vuex-multi-tab');
} catch (error) {
  // vuex-multi-tab doesn't allow the data in the store to be "initialized" and permanently saves it. It should be reset on load and be loaded from the store.
  // https://github.com/gabrielmbmb/vuex-multi-tab-state/issues/63
}

import Vuex from 'vuex';
Vue.use(Vuex);

const emptyState = {
  initialized: false,

  // Notifications handling throughout the UI, a list of notifications and their current state
  alert: null,
  hiddenNotificationsV2: [],
  errorTracking: {},

  connectionName: null,
  globalLoading: true,
  profile: {},
  hasAccessToUI: null,
  userCreatedAccountThisSession: false,
  settings: {
    language: 'en',
    regionalSettings: 'ISO8601'
  },
  permanentCache: {
    authressDomain: null,
    lastLogout: null,
    lastLoginTime: null,
    referrerData: null
  },
  cache: {
    currentAccount: null,
    billingAccount: null,
    inviteId: null,
    accounts: [],
    invalidTokenCalls: [],
    errorTracking: {
      NetworkError: { count: 0, lastFoundDate: null },
      RateLimitError: { count: 0, lastFoundDate: null },
      chunkLoadingError: null
    },

    onboardingInfo: {
      newsletterSignup: false
    },
    refundData: null,

    alerts: [],
    clients: [],
    roles: [],
    records: [],
    groups: [],
    extensions: [],
    tags: [],
    resources: {},
    requests: [],
    customDomains: [],
    applications: [],
    connections: [],
    tenants: [],
    userMap: {},
    usage: null,
    usageHistory: null,
    auditEventRepositoryLastUpdated: null
  },
  developmentApp: false
};

const plugins = [
  createMultiTabState({ statesPaths: Object.keys(emptyState).filter(key => key !== 'alert' && key !== 'errorTracking' && key !== 'globalLoading') }),
  createPersistedState({ key: 'Authress', paths: ['settings', 'profile', 'cache', 'permanentCache', 'developmentApp', 'hiddenNotificationsV2'] })
];
const isDevelopment = process.env.NODE_ENV && process.env.NODE_ENV === 'development';

// import createLogger from 'vuex/dist/logger';
// if (isDevelopment) { plugins.push(createLogger()); }

function isRhosysAdmin(state) {
  return state.profile?.userId === 'Authress|google-oauth2|100822687410662214374' || state.profile?.userId === 'Authress|google-oauth2|109357119042447700062';
}

export default new Vuex.Store({
  strict: isDevelopment,
  plugins: plugins,
  state: cloneDeep(emptyState),
  mutations: {
    /* UI Handlers */
    initialize(state) {
      state.alert = null;
      state.initialized = true;

      // Remove legacy properties that are no longer used and might take up space in a users browser, whenever we remove a property from the store from above, it should also be added here
      delete state.cache.authorizationUsageEvents;
    },

    setGlobalLoading(state, value) {
      state.globalLoading = value || false;
    },

    setUiDisplayPageNotificationAlert(state, alert) {
      state.alert = alert;
    },
    removeAlert(state, options) {
      if (options.force || !state.alert?.persistent) {
        if (options.type) {
          if (state.alert && state.alert.alertType === options.type) {
            state.alert = null;
          }
        } else {
          state.alert = null;
        }
      }
    },
    removeSpecificAlert(state, alert) {
      if (state.alert === alert) {
        state.alert = null;
      }
    },
    setLanguage(state, language) {
      state.settings.language = language;
    },
    hidePageNotification(state, { pageNotificationId, hideUntilDateTime }) {
      const accountId = state.cache.currentAccount?.accountId;
      state.hiddenNotificationsV2 = state.hiddenNotificationsV2.filter(n => n.pageNotificationId !== pageNotificationId).concat({
        pageNotificationId, accountId,
        hideUntilDateTime: (hideUntilDateTime || DateTime.utc().plus({ weeks: 1 })).toISO()
      }).filter(n => DateTime.utc() < DateTime.fromISO(n.hideUntilDateTime));
    },

    setErrorTracking(state, { errorType, data }) {
      if (data) {
        Vue.set(state.errorTracking, errorType, { ...data, lastFoundDate: DateTime.utc().toISO() });
      } else {
        Vue.delete(state.errorTracking, errorType);
      }
    },

    cacheRefundInformation(state, refundData) {
      state.cache.refundData = refundData;
    },

    /* ***************/

    /* Log in handling */
    setPreferredConnection(state, { connectionName, authressDomain }) {
      state.connectionName = connectionName;
      state.permanentCache.authressDomain = authressDomain;
    },
    updateProfile(state, profile) {
      state.profile = profile || {};
    },
    setLoginTime(state, lastLoginTime) {
      state.permanentCache.lastLoginTime = lastLoginTime;
    },
    removeLogin(state, logoutDateTimeNonce) {
      state.globalLoading = true;
      const isDev = state.developmentApp;
      const initialized = state.initialized;
      const permanentCache = state.permanentCache;
      const emptyStateInstance = cloneDeep(emptyState);
      Object.keys(emptyStateInstance).forEach(key => {
        state[key] = emptyStateInstance[key];
      });
      state.permanentCache = permanentCache;
      state.initialized = initialized;
      state.developmentApp = isDev;
      state.permanentCache.lastLogout = logoutDateTimeNonce;
    },
    setAppDevelopmentVersion(state, developmentApp) {
      state.developmentApp = developmentApp;
    },
    /* ***************/

    /* Error handling */
    trackChunkLoadingError(state, data) {
      state.cache.errorTracking.chunkLoadingError = data;
    },
    trackHttpSuccess(state) {
      state.cache.errorTracking.NetworkError = { count: 0 };
    },
    trackHttpError(state, errorType) {
      state.cache.errorTracking[errorType].count++;
      state.cache.errorTracking[errorType].lastFoundDate = DateTime.utc().toISO();
    },
    setInvalidTokenCalls(state, value) {
      if (!value || !Array.isArray(state.cache.invalidTokenCalls)) {
        state.cache.invalidTokenCalls = [];
      }

      state.cache.invalidTokenCalls = state.cache.invalidTokenCalls.concat(value || []);
    },
    /* ***************/

    setAccessToUI(state, access) {
      state.hasAccessToUI = !!access;
    },

    setOnboarding(state, onboardingInfo) {
      state.cache.onboardingInfo = Object.assign({}, cloneDeep(state.cache.onboardingInfo), onboardingInfo);
    },

    /* Account Management */
    setAccounts(state, accounts) {
      const newAccountsMap = (accounts || []).reduce((agg, a) => { agg[a.accountId] = true; return agg; }, {});
      state.cache.accounts = state.cache.accounts.filter(a => !newAccountsMap[a.accountId]).concat(accounts || []);

      if (!state.cache.currentAccount || !state.cache.accounts.some(a => state.cache.currentAccount.accountId === a.accountId)) {
        state.cache.currentAccount = state.cache.accounts.find(a => a?.domain) || state.cache.accounts[0];
      }
    },
    restrictAccountList(state, accounts) {
      const allowedMap = accounts.reduce((acc, a) => { acc[a.accountId] = true; return acc; }, {});
      state.cache.accounts = state.cache.accounts.filter(a => a.persist || allowedMap[a.accountId]);

      if (state.cache.currentAccount && !state.cache.accounts.some(a => state.cache.currentAccount.accountId === a.accountId)) {
        this.setCurrentAccount(state, state.cache.accounts[0]);
      }
    },
    createAccount(state, account) {
      state.cache.accounts = [account].concat(state.cache.accounts.filter(a => a.accountId !== account.accountId));
      state.cache.currentAccount = state.cache.accounts.find(a => a?.domain) || state.cache.accounts[0];
      state.userCreatedAccountThisSession = true;

      const emptyStateInstance = cloneDeep(emptyState);
      delete emptyStateInstance.cache.currentAccount;
      delete emptyStateInstance.cache.accounts;
      state.cache = { currentAccount: state.cache.currentAccount, accounts: state.cache.accounts, ...emptyStateInstance.cache };
    },
    updateAccount(state, account) {
      const combinedAccountData = cloneDeep(account);
      combinedAccountData.persist = combinedAccountData.persist || state.cache.accounts.find(a => a.accountId === account.accountId)?.persist;
      state.cache.accounts = [combinedAccountData].concat(state.cache.accounts.filter(a => a.accountId !== account.accountId));
      state.cache.currentAccount = state.cache.accounts.find(a => a?.domain) || state.cache.accounts[0];
    },
    setCurrentAccount(state, accountId) {
      state.cache.currentAccount = state.cache.accounts.find(a => a.accountId === accountId);
      const emptyStateInstance = cloneDeep(emptyState);
      delete emptyStateInstance.cache.currentAccount;
      delete emptyStateInstance.cache.accounts;
      state.cache = { currentAccount: state.cache.currentAccount, accounts: state.cache.accounts, ...emptyStateInstance.cache };
    },
    setBillingAccount(state, billingAccount) {
      state.cache.billingAccount = billingAccount;
    },
    setInviteId(state, inviteId) {
      state.cache.inviteId = inviteId;
    },
    /** *****************/

    setRoles(state, roles) {
      state.cache.roles = roles || [];
    },
    setRole(state, { roleId, role }) {
      state.cache.roles = state.cache.roles.filter(r => r.roleId !== roleId).concat(role ? role : []);
    },
    setRecords(state, records) {
      const newRecordMap = (records || []).reduce((agg, r) => { agg[r.recordId] = true; return agg; }, {});
      const filteredRecords = (state.cache.records || []).filter(r => !newRecordMap[r.recordId]);
      const newRecords = records.map(r => ({
        ...r,
        lastFetchedDateTime: DateTime.utc().toMillis()
      }));
      state.cache.records = (newRecords || []).concat(filteredRecords);
    },
    resetRecords(state, records) {
      state.cache.records = (records || []);
    },
    setRecord(state, { recordId, record }) {
      state.cache.records = state.cache.records.filter(r => r.recordId !== recordId).concat(record ? { ...record, lastFetchedDateTime: DateTime.utc().toMillis() } : []);
    },
    setRequests(state, requests) {
      state.cache.requests = requests || [];
    },
    setRequest(state, { requestId, request }) {
      state.cache.requests = state.cache.requests.filter(r => r.requestId !== requestId).concat(request ? request : []);
    },

    /* TAGS */
    cacheTags(state, rawTagLists) {
      if (!rawTagLists) {
        return;
      }
      if (!Array.isArray(state.cache.tags)) {
        state.cache.tags = [];
      }

      const tagLists = Array.isArray(rawTagLists) ? rawTagLists : [rawTagLists];

      tagLists.filter(t => t).map(tags => {
        const tagObjects = Object.keys(tags).map(t => {
          if (Array.isArray(tags[t])) {
            return tags[t].map(tagValue => ({ key: t, value: tagValue }));
          }

          return [{ key: t, value: tags[t] }];
        }).flat(1);

        tagObjects.map(tagObject => {
          const tagIsAlreadyAnOption = state.cache.tags.some(currentTagObject => {
            return tagObject.key === currentTagObject.key && tagObject.value === currentTagObject.value;
          });

          if (!tagIsAlreadyAnOption) {
            state.cache.tags.push(tagObject);
          }
        });
      });
    },

    /* GROUPS */
    setGroups(state, groups) {
      const newGroupMap = (groups || []).reduce((agg, r) => { agg[r.groupId] = true; return agg; }, {});
      const filteredGroups = (state.cache.groups || []).filter(r => !newGroupMap[r.groupId]);
      state.cache.groups = (groups || []).concat(filteredGroups);
    },
    setGroup(state, { groupId, group }) {
      state.cache.groups = state.cache.groups.filter(r => r.groupId !== groupId).concat(group ? group : []);
    },
    /* GROUPS */

    /* CLIENTS */
    setClient(state, { clientId, client }) {
      state.cache.clients = state.cache.clients.filter(r => r.clientId !== clientId).concat(client ? client : []);
    },

    setClients(state, clients) {
      state.cache.clients = clients || [];
    },
    /* ************************************************/

    cacheResources(state, resources) {
      if (!state.cache.resources) {
        state.cache.resources = {};
      }

      const resourcesArray = Array.isArray(resources) && resources || resources && [resources] || [];
      resourcesArray.flat(100).filter(r => r).map(r => {
        Vue.set(state.cache.resources, r, true);
      });
    },

    /* EXTENSIONS */
    setExtensions(state, extensions) {
      const newExtensionMap = (extensions || []).reduce((agg, r) => { agg[r.extensionId] = true; return agg; }, {});
      const filteredExtensions = (state.cache.extensions || []).filter(r => !newExtensionMap[r.extensionId]);
      state.cache.extensions = (extensions || []).concat(filteredExtensions);
    },
    resetExtensions(state, extensions) {
      state.cache.extensions = (extensions || []);
    },
    setExtension(state, { extensionId, extension }) {
      state.cache.extensions = state.cache.extensions.filter(r => r.extensionId !== extensionId).concat(extension ? extension : []);
    },
    /* ALERTS */
    setAlerts(state, alerts) {
      state.cache.alerts = alerts || [];
    },
    setAlert(state, { alertId, alert }) {
      state.cache.alerts = state.cache.alerts.filter(r => r.alertId !== alertId).concat(alert ? alert : []);
    },
    /* CONNECTIONS */

    setConnections(state, connections) {
      state.cache.connections = connections || [];
    },
    setConnection(state, { connectionId, connection }) {
      state.cache.connections = state.cache.connections.filter(r => r.connectionId !== connectionId).concat(connection ? connection : []);
    },
    setTenants(state, tenants) {
      state.cache.tenants = tenants || [];
    },
    setTenant(state, { tenantId, tenant }) {
      state.cache.tenants = state.cache.tenants.filter(r => r.tenantId !== tenantId).concat(tenant ? tenant : []);
    },

    setApplications(state, applications) {
      state.cache.applications = applications || [];
    },
    setApplication(state, { applicationId, application }) {
      state.cache.applications = state.cache.applications.filter(r => r.applicationId !== applicationId).concat(application ? application : []);
    },
    setDomains(state, domains) {
      state.cache.customDomains = domains || [];
    },
    addDomain(state, domain) {
      if (state.cache.customDomains.some(d => d.domain === domain && d.status !== 'FAILED')) {
        return;
      }

      state.cache.customDomains = state.cache.customDomains.filter(d => d.domain !== domain).concat({ domain });
    },
    removeDomain(state, domain) {
      state.cache.customDomains = state.cache.customDomains.filter(d => d.domain !== domain);
    },

    addUserData(state, { userId, user }) {
      if (!userId) {
        return;
      }
      const convertedUserId = userId.replace(/^Authress\|/i, '');

      Vue.set(state.cache.userMap, userId, user);
      Vue.set(state.cache.userMap, convertedUserId, user);
    },
    setUsage(state, { usage, history }) {
      state.cache.usage = usage;
      state.cache.usageHistory = history;
    },
    setReferrerData(state, referrerData) {
      state.permanentCache.referrerData = Object.values(referrerData || {}).some(v => v) ? referrerData : state.permanentCache.referrerData;
    },
    /* ***************/
    /* auditEventRepositoryUpdated */
    auditEventRepositoryUpdated(state) {
      state.cache.auditEventRepositoryLastUpdated = DateTime.utc().toISO();
    }
  },
  getters: {
    enableAdminDisplay(state) {
      return state.cache.accounts.length > 5;
    },
    completedCustomDomain(state) {
      return state.cache.customDomains?.find(d => d.status === 'COMPLETED')?.domain;
    },
    currentAccount(state) {
      const currentAccountId = state.cache.currentAccount?.accountId;
      return state.cache.accounts.find(a => a.accountId === currentAccountId) || state.cache.currentAccount;
    },
    isRhosysAdmin(state) {
      return isRhosysAdmin(state);
    },
    hasAccessToUI(state) {
      return state.hasAccessToUI !== false;
    },
    tags(state) {
      // Auto generated tag is generated by Authress, don't let users select that.
      return state.cache.tags.filter(t => t.key !== 'Autogenerated');
    },
    resources(state) {
      return Object.keys(state.cache.resources).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    },
    combinedUserMap(state) {
      const newMap = cloneDeep(state.cache.userMap || {});

      (state.cache.clients || []).map(client => {
        newMap[client.clientId] = { name: client.name || '', serviceClient: true, userId: client.clientId };
      });
      return newMap;
    },
    getHiddenNotifications(state) {
      if (!Array.isArray(state.hiddenNotificationsV2)) {
        return {};
      }

      const hiddenNotifications = {};
      state.hiddenNotificationsV2.map(n => {
        if (n.accountId === state.cache.currentAccount?.accountId
          && (!n.hideUntilDateTime || DateTime.utc() < DateTime.fromISO(n.hideUntilDateTime))) {
          hiddenNotifications[n.pageNotificationId] = n;
        }
      });
      return hiddenNotifications;
    }
  }
});
