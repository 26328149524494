/* eslint-disable no-console */
import 'url-polyfill';
import axios from 'axios';
import uuid from 'uuid';
import stringify from 'json-stringify-safe';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';

import buildInfo from '../buildInfo';
import environment from '../environment';
import store from '../store';

const sessionIdKey = 'authress.io-sessionId';
const client = axios.create({ baseURL: 'https://relay.rhosys.ch/v1/logs' });

class Logger {
  constructor(storageProvider) {
    this.getUserData = () => {};
    this.storageProvider = storageProvider || typeof localStorage !== 'undefined' && localStorage || null;

    this.messagesToPost = [];
    if (typeof window !== 'undefined') {
      window.setInterval(() => this.flush(), 15000);
    }
  }

  initialize() {
    this.sessionKey = this.storageProvider.getItem(sessionIdKey) || uuid.v4();
    try {
      this.storageProvider.setItem(sessionIdKey, this.sessionKey);
      const cookieDomain = window.location.hostname.split('.').reverse().slice(0, 2).reverse().join('.');
      const newCookie = `Session=${this.sessionKey}; expires=${DateTime.utc().plus({ years: 1 }).toHTTP()}; path=/; domain=${cookieDomain}; SameSite=Lax`;
      document.cookie = newCookie;
    } catch (error) {
      this.error({ title: 'Failed to initialize logger correctly', error });
    }
  }

  /**
   * Log critical errors breaking application behavior
   * @param {String} message - Message to be logged
   * @param {Boolean} [display=true] - Whether to display message in the console
   */
  critical(message, display = true) {
    if (display) {
      console.error(message);
    } else {
      console.debug(message);
    }
    this.logInternal(message, 'CRITICAL');
  }

  /**
   * Log errors indicating unexpected, but handled behavior
   * @param {String} message - Message to be logged
   * @param {Boolean} [display=true] - Whether to display message in the console
   */
  error(message, display = true) {
    if (display) {
      console.error(message);
    } else {
      console.debug(message);
    }
    this.logInternal(message, 'ERROR');
  }

  /**
   * Log warnings indicating undesired situations that don't interrupt application flow
   * @param {String} message - Message to be logged
   * @param {Boolean} [display=true] - Whether to display message in the console
   */
  warn(message, display = true) {
    if (display) {
      console.warn(message);
    } else {
      console.debug(message);
    }
    this.logInternal(message, 'WARN');
  }

  /**
   * Log information about standard application behavior
   * @param {String} message - Message to be logged
   * @param {Boolean} [display=true] - Whether to display message in the console
   */
  log(message, display = true) {
    if (display) {
      console.info(message);
    } else {
      console.debug(message);
    }
    this.logInternal(message, 'INFO');
  }

  /**
   * Log information about standard application behavior
   * @param {String} message - Message to be logged
   * @param {Boolean} [display=true] - Whether to display message in the console
   */
  info(message, display = true) {
    if (display) {
      console.info(message);
    } else {
      console.debug(message);
    }
    this.logInternal(message, 'INFO');
  }

  /**
   * Log tracking info about standard application behavior
   * @param {String} message - Message to be logged
   * @param {Boolean} [display=true] - Whether to display message in the console
   */
  track(message, display = false) {
    if (display) {
      console.info(message);
    } else {
      console.debug(message);
    }
    this.logInternal(message, 'TRACK');
  }

  /**
   * Log low-level information about application behavior that doesn't need to be collected
   * NOTE: Requires selecting "Verbose" logging level in Chrome
   * @param {String} message - Message to be logged
   * @param {Boolean} [display=false] - Whether to display message in the console
   */
  debug(message, display = false) {
    if (display || environment === 'development') {
      console.debug(message);
    }
    this.logInternal(message, 'DEBUG');
  }

  logInternal(message, level = 'INFO') {
    if (!message) {
      console.error('Sumo Logic Logger requires that you pass a value to log.');
      return;
    }

    const type = typeof message;
    let messageAsObject = message;
    if (type === 'undefined' || (type === 'string' && message === '')) {
      console.error('Sumo Logic Logger requires that you pass a value to log.');
      return;
    } else if (type === 'string') {
      messageAsObject = {
        title: message
      };
    } else if (type === 'object' && Object.keys(message).length === 0) {
      console.error('Sumo Logic Logger requires that you pass a non-empty JSON object to log.');
      return;
    }

    const payload = {
      timestamp: new Date().toISOString(),
      user: store.state.profile.userId || 'unknown',
      accountId: store.getters.currentAccount?.accountId || 'unknown',
      url: window.location.href,
      sessionUrl: posthog.get_session_replay_url({ withTimestamp: true, timestampLookBack: 30 }),
      route: this.getRoute ? this.getRoute() : 'unknown',
      version: buildInfo.version,
      userAgent: window.navigator?.userAgentData || window.navigator?.userAgent,
      environment: environment || 'local',
      level: level,
      sessionId: this.sessionKey,
      referrerData: store.state.permanentCache.referrerData,
      userData: this.getUserData(),
      message: messageAsObject
    };

    // convert an error object to a json object
    const replaceErrors = (_, value) => {
      try {
        if (value instanceof Error) {
          const error = {};
          Object.getOwnPropertyNames(value).forEach(key => {
            error[key] = value[key];
          });
          return error;
        } else if (value instanceof URL) {
          return value.toString();
        }
      } catch (error) {
        console.error('Failed to stringify log value', _, value, payload);
        this.messagesToPost.push(stringify({ title: 'Failed to serialize error in logger', level: 'ERROR', failedKeyValue: { key: _, value }, error: { message: error.message, code: error.code, stack: error.stack } }));
        return '<Logger-FailedToSerialize>';
      }
      return value;
    };

    try {
      this.messagesToPost.push(this.truncateToken(stringify(payload, replaceErrors)));
    } catch (error) {
      console.error('Failed to stringify log message', payload);
    }

    if (environment === 'development') {
      return;
    }

    if (this.messagesToPost.length > 25) {
      this.flush();
    }
  }

  /**
   * @description Gets the next set of messages as payload, and resets the current messages to an empty array.
   */
  nextMessagesAsPayload() {
    const payload = this.messagesToPost.reduce((acc, curr) => `${acc}${curr}\n`, '');
    this.messagesToPost = [];
    return payload;
  }

  /**
   * @description Flushes the current messages and sends them to SumoLogic
   */
  async flush() {
    if (environment === 'development') {
      return;
    }
    
    if (this.messagesToPost.length === 0) {
      return;
    }

    try {
      await client.post(client.defaults.baseURL, this.nextMessagesAsPayload(), {
        headers: {
          'Content-Type': 'text/plain',
          'X-Sumo-Name': 'Website',
          'X-Sumo-Category': buildInfo.deployment.logTarget
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @description Flushes the remaining messages at the time when the user navigates to another window
   *              This is not supported on all browsers yet https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
   *              But it's probably the best to catch the remaining messages that might not yet have been sent yet.
   *              Any asynchronous calls (that is, calls through axios) won't be executed during the window unload event.
   */
  flushOnUnload() {
    if (environment === 'development') {
      return;
    }

    try {
      if (navigator.sendBeacon && this.messagesToPost.length > 0) {
        const data = this.nextMessagesAsPayload();
        // navigator.sendBeacon must not trigger CORS preflight calls on Chrome
        // this this bug: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Preflighted_requests
        // ensure the type is set to text/plain and not application/json, since only a small selection
        // of content types won't trigger a preflight request. See https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Preflighted_requests
        const blob = new Blob([data], { type: 'text/plain' });
        navigator.sendBeacon(client.defaults.baseURL, blob);
      }
    } catch (error) {
      /* If it can't happen then it can't happen */
    }
  }

  /**
   * @description Truncates all oauth token occurrences in given json payload. In case of JWT the header and payload part will remain visible for analysis.
   * @param {String} payload stringified json payload
   * @returns {String} stringified json payload with truncated oauth tokens
   */
  truncateToken(payload) {
    return payload.replace(/(eyJ[a-zA-Z0-9_-]{5,}\.eyJ[a-zA-Z0-9_-]{5,})\.[a-zA-Z0-9_-]*/gi, (m, p1) => `${p1}.<sig>`);
  }
}

export default new Logger();
