import posthog from 'posthog-js';
import 'url-polyfill';
import jwtManager from './jwtManager';
import queryString from 'query-string';
import { DateTime } from 'luxon';

import { LoginClient, UserConfigurationScreen } from '@authress/login';

import store from '../store';
import environment from '../environment';
import logger from '../clients/logger';
import alertHandler from '../errorHandling/alertHandler';
import auditEventRepository from '@/components/audit/auditEventRepository';

const loginClient = new LoginClient({ authenticationServiceUrl: 'https://login.authress.io', applicationId: 'AUTHRESS' }, logger);

let initializedResolver;

class AuthManager {
  constructor() {
    this.routeGuardTriggered = false;

    this.initialized = false;
    this.initializedAsync = new Promise(resolve => initializedResolver = resolve);

    this.ssoAccessToken = null;
    this.loginHooks = [];

    this.logoutDateTimeNonce = null;
    // If a log out is triggered from another window then redirect this one to the origin as well
    store.watch(state => state.permanentCache.lastLogout, newLogoutNonce => {
      if (newLogoutNonce !== this.logoutDateTimeNonce && this.initialized) {
        this.logoutDateTimeNonce = newLogoutNonce;
        if (environment === 'development') {
          window.location.assign(`${window.location.origin}/#/login`);
          return;
        }

        window.location.assign(new URL('/app/#/login', window.location.origin).toString());
      }
    });
  }

  addLoginHook(hook) {
    this.loginHooks.push(hook);
  }

  trackUnauthorizedRequest(error) {
    if (Array.isArray(store.state.cache.invalidTokenCalls) && store.state.cache.invalidTokenCalls.length >= 2) {
      logger.error({ title: 'total allowed unauthorized requests exceeded, resetting the token, but this should not be happening',
        invalidTokenCalls: store.state.cache.invalidTokenCalls, source: 'authManager.trackUnauthorizedRequest' });
      store.commit('setInvalidTokenCalls', null);
      this.logOut(true);
      setTimeout(() => window.location.reload(), 200);
    } else {
      store.commit('setInvalidTokenCalls', error);
    }
  }

  loggedInGuard(requireAuth) {
    const stripQueryFromWindow = () => {
      const replacement = new URL(window.location.href);
      replacement.search = '';
      window.history.replaceState({}, document.title, replacement.toString());
    };
    return async (to, from, next) => {
      if (requireAuth) {
        this.routeGuardTriggered = true;
      }

      // require login is a guard so fundamentally we are not at the location we want to be after the redirect.  So let's save instead the new destination, and navigate there instead of here.
      const hashLocation = window.location.href.indexOf('#');
      const origin = hashLocation !== -1 ? window.location.href.substring(0, hashLocation) : window.location.href;
      const redirectUri = `${origin}#${to.fullPath}`;
      const options = {
        requireAuth,
        redirectUri,
        explicitConnection: store.state.connectionName
      };

      if (window.location.href.match('x-amzn-marketplace-token')) {
        logger.log({ title: 'Found x-amzn-marketplace-token in URL from Amazon Billing Setup.', level: 'INFO' });
      }

      const query = queryString.parse(window.location.search);
      if (query?.state && (query.admin_consent || query.state === JSON.stringify({ name: 'Settings', query: { focus: 'general', tab: 'sso' } }))) {
        try {
          stripQueryFromWindow();
          next({ name: 'Settings', query: { focus: 'general', tab: 'sso' } });
          return;
        } catch (error) {
          logger.error({ title: 'Failed to parse expected query string', to, from, next, query, location: window.location, error });
        }
      }

      try {
        await this.ensureLoggedIn(options);
      } catch (error) {
        store.commit('updateProfile', {});
        if (error.code === 'login_required' || error.code === 'ForceLogin' || error.code === 'consent_required') {
          if (requireAuth) {
            next({ name: 'Login', query: { redirectUri } });
            return;
          }
          next();
          return;
        }

        if (error.code !== 'access_denied') {
          logger.warn({ title: 'Failed to log the user in', source: 'loggedInGuard', to: to?.name, from: from?.name, options, location: window.location, error });
          alertHandler.renderAlert('Login unsuccessful', 'The login provider failed to respond with a valid authentication token.<br>This can occur if they are currently having a status incident. <br><br>Automatically retrying...<br><br><small>If you continue to experience issues, please contact <a href="mailto:support@authress.io">support@authress.io</a>.</small>', 'danger', 4000, true);
          next(false);
          setTimeout(async () => {
            try {
              alertHandler.removeAlert('danger', true);
              await this.logOut(true);
              await this.ensureLoggedIn(options);
            } catch (innerError) {
              logger.error({ title: 'Failed to log the user in on retry', to: to?.name, from: from?.name, options, location: window.location, error, innerError });
              alertHandler.renderAlert('Login unsuccessful', 'The login provider failed to respond with a valid authentication token.<br>This can occur if they are currently having a status incident. Please try again by using the Log in button in the navigation bar.<br><br>If you continue to experience issues, please contact <a href="mailto:support@authress.io">support@authress.io</a>.', 'danger', 10000, true);
            }
          }, 4000);
          return;
        }

        const profile = await this.getExistingUserSessionInfo();
        if (!profile) {
          alertHandler.renderAlert('Login unsuccessful', `The response from the login provider during log in was '${error.details}'.<br><br>This can occur if they are currently having a status incident or the permission access was disallowed during login. Please try again by using the Log in button in the navigation bar.<br><br>If you continue to experience issues, please contact <a href="mailto:support@authress.io">support@authress.io</a>.`, 'danger', 10000, true);
          // Can't be login or else there will be an infinite loop
          next(false);
          return;
        }
      }

      try {
        const profile = await this.getExistingUserSessionInfo();
        store.commit('updateProfile', profile);
        if (!profile) {
          if (!requireAuth) {
            stripQueryFromWindow();
            next();
            return;
          }
          // Can't be login or else there will be an infinite loop
          logger.log({ title: 'User is not logged in and they must be before continuing, blocking navigation' });
          next(false);
          return;
        }

        if (!this.initialized) {
          Promise.all(this.loginHooks.map(hook => hook())).catch(() => {}).then(() => { initializedResolver(); });
          this.initialized = true;
        }
        stripQueryFromWindow();
        next();
      } catch (error) {
        logger.error({ title: 'Failed to decode and set profile from user identity.', to: to?.name, from: from?.name, options, location: window.location, error });
        alertHandler.renderAlert('Login unsuccessful', 'The response from the login provider during log in was invalid.<br><br>This can occur if they are currently having a status incident or the permission access was disallowed during login. Please try again by using the Log in button in the navigation bar.<br><br>If you continue to experience issues, please contact <a href="mailto:support@authress.io">support@authress.io</a>.', 'danger', 10000, true);
        // Can't be login or else there will be an infinite loop
        next(false);
      }
    };
  }

  async logOut(stayInApp) {
    await auditEventRepository.deleteTheCurrentDatabase();
    this.logoutDateTimeNonce = DateTime.utc().toISO();
    store.commit('removeLogin', this.logoutDateTimeNonce);
    await loginClient.logout();
    if (environment === 'development') {
      window.location.assign(`${window.location.origin}/#/login`);
      return;
    }

    if (stayInApp) {
      window.location.assign(new URL('/app/#/login', window.location.origin).toString());
      return;
    }

    try {
      await posthog.reset();
    } catch (error) {
      logger.log({ title: 'Failed to reset posthog', level: 'ERROR', error });
    }
    window.location.assign(window.location.origin);
  }

  getToken(options) {
    return loginClient.ensureToken(Object.assign({ timeoutInMillis: 10000 }, options));
  }

  async ensureLoggedIn(options = { explicitConnection: null, requireAuth: false, requiredUri: null, force: false }) {
    const authressDomain = store.state.permanentCache.authressDomain;
    const connectionId = authressDomain && `${authressDomain}.api.authress.io` || options.explicitConnection;

    const isUserLoggedIn = await loginClient.userSessionExists();
    if (!options.requireAuth) {
      return;
    }

    if (!options?.force && isUserLoggedIn && store.state.permanentCache.lastLoginTime
      // Force everyone to re-login for any reason
      // * Don't set this to the future, it will break things. This must always be in the past
      && DateTime.fromISO('2023-06-26T13:58:00Z') < DateTime.fromISO(store.state.permanentCache.lastLoginTime)) {
      return;
    }

    // Otherwise force the users to re-login
    if (isUserLoggedIn) {
      logger.log({ title: 'Forcing the user to re-login', lastLoginTime: store.state.permanentCache.lastLoginTime });

      // We need to force the user to log out because when we drop down below the user will still have a session but connectionId might not be set.
      // * When connectionId is null, we throw an error and redirect the user to login
      // * But when a session is still present, the session will repopulate credentials
      // * That means when a connectionId is null and force is true
      await this.logOut(true);
    }

    try {
      const connectionProperties = connectionId === 'microsoft' ? { access_type: 'offline', scope: 'email openid profile User.ReadBasic.All offline_access' } : undefined;
      store.commit('setLoginTime', DateTime.utc().toISO());
      const inviteId = store.state.cache.inviteId;
      await loginClient.authenticate({ force: true, redirectUrl: options.redirectUri, connectionId, connectionProperties, inviteId });
    } catch (error) {
      if (error.code === 'InvalidConnection' || error.code === 'InvalidTenantIdentifier' || error.code === 'InvalidApplication' || error.data?.errorCode === 'InvalidApplication') {
        logger.debug({ title: 'Failed to log user in due to invalid stored preferred connection configuration, sending the user back to the login screen.', error, options }, false);
        store.commit('setPreferredConnection', {});
        throw Error.create('ForceLogin');
      }
      logger.warn({ title: 'Failed to log user in', source: 'ensureLoggedIn', store: store.state, error, options, connectionId, authressDomain });
      throw error;
    }
  }

  async getExistingUserSessionInfo() {
    const userData = loginClient.getUserIdentity();
    if (!userData) {
      return null;
    }
    const token = await this.getToken();
    const identity = token && jwtManager.decode(token);
    return identity && {
      userId: identity.iss === 'https://login.authress.io' ? `Authress|${identity.sub}` : identity.sub,
      email: userData.email,
      username: userData.username,
      displayName: userData.displayName || userData.name
    };
  }

  getExistingUserIdentity() {
    return loginClient.getUserIdentity();
  }

  async goToSecurityKeys() {
    await loginClient.openUserConfigurationScreen({ redirectUrl: window.location.href, startPage: UserConfigurationScreen.MFA });
  }

  /**
   * Prevent open redirector which could be used to abuse Authress to create phishing domains
   * @param {*} unsafeRedirectUrl
   * @returns string - safe redirect urls
   */
  getSafeRedirectUrl(unsafeRedirectUrl) {
    if (!unsafeRedirectUrl) {
      return null;
    }

    try {
      const url = new URL(unsafeRedirectUrl);
      if (url.host === 'authress.io' || url.hostname === 'localhost') {
        return url.toString();
      }
    } catch (error) {
      logger.warn({ title: 'Failed to parse redirect url safely', unsafeRedirectUrl, error });
    }
    logger.error({ title: 'Invalid redirect url, it is unsafe', unsafeRedirectUrl });
    return null;
  }
}

export default new AuthManager();
