// https://www.svgrepo.com/vectors
import AppleBrandIcon from './logos/apple_logo.svg';
import GoogleBrandIcon from '../components/manage/connections/google_logo.svg';
import FacebookBrandIcon from '@/components/manage/connections/facebook_logo.svg';
import LinkedInBrandIcon from '@/components/manage/connections/linkedin_logo.svg';
import MicrosoftBrandIcon from '../components/manage/connections/microsoft_logo.svg';
import GithubBrandIcon from '../components/manage/connections/github.svg';
import GitlabBrandIcon from '../components/manage/connections/gitlab_logo.svg';
import ProcoreBrandIcon from '../components/manage/connections/procore_logo.svg';
import CognitoBrandIcon from '../components/manage/connections/aws_cognito_logo.svg';
import SlackBrandIcon from '../components/manage/connections/slack_logo.svg';
import SalesforceBrandIcon from '../components/manage/connections/salesforce_logo.svg';
import AwsBrandIcon from '../components/manage/connections/aws_logo.svg';
import AmazonBrandIcon from '../components/manage/connections/amazon_logo.svg';
import HubspotBrandIcon from '../components/manage/connections/hubspot_logo.svg';
import SteamBrandIcon from '../components/manage/connections/steam_logo.svg';
import ZohoBrandIcon from './logos/zoho_logo.svg';
import SwissIdBrandIcon from './logos/swissid_logo.svg';
import StripeBrandIcon from '../components/manage/connections/stripe_logo.svg';
import YahooBrandIcon from './logos/yahoo_logo.svg';
import OracleNetsuiteBrandIcon from '../components/manage/connections/oracle_netsuite_logo.svg';
import EthereumBrandIcon from '../components/manage/connections/ethereum_logo.svg';

// Changes to these properties should be reflected in Restrict during onboarding
const preferredConnections = {
  con_apple: {
    brandIcon: AppleBrandIcon,
    useFormPostCallbackEndpoint: true,
    authenticationUrl: 'https://appleid.apple.com/auth/authorize', tokenUrl: 'https://appleid.apple.com/auth/token',
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-apple-login',
    // Alternate link to https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_rest_api after we have our own article
    helpLink: 'https://developer.apple.com/documentation/sign_in_with_apple/configuring_your_environment_for_sign_in_with_apple', helpText: 'Sign in with Apple',
    data: { name: 'Apple', supportedContentType: 'application/x-www-form-urlencoded' },
    defaultConnectionProperties: { scope: 'openid email name' }
  },
  con_aws: {
    brandIcon: AwsBrandIcon,
    authenticationUrl: '', tokenUrl: 'https://aws.amazon.com/token',
    authressSetupGuide: '',
    helpLink: 'https://signin.aws.amazon.com/saml', helpText: 'AWS Account login',
    data: { name: 'AWS' }
  },
  con_amazon: {
    brandIcon: AmazonBrandIcon,
    authenticationUrl: 'https://www.amazon.com/ap/oa', tokenUrl: 'https://api.amazon.com/auth/o2/token',
    authressSetupGuide: '',
    helpLink: 'https://developer.amazon.com/docs/login-with-amazon/register-web.html', helpText: 'Login with Amazon (LWA)',
    data: { name: 'Amazon', oidcUserEndpointUrl: 'https://api.amazon.com/user/profile', userIdExpression: '{user_id}', supportedContentType: 'application/x-www-form-urlencoded' },
    defaultConnectionProperties: { scope: 'profile profile:user_id' }
  },
  con_ethereum: {
    brandIcon: EthereumBrandIcon,
    displayName: 'Ethereum DAO Managed Login',
    authenticationUrl: 'https://oidc.signinwithethereum.org/authorize', tokenUrl: 'https://oidc.signinwithethereum.org/token',
    authressSetupGuide: '',
    helpLink: 'https://docs.login.xyz/servers/oidc-provider/hosted-oidc-provider', helpText: 'Ethereum login',
    data: { name: 'Ethereum', supportedContentType: 'application/x-www-form-urlencoded' },
    defaultConnectionProperties: { scope: 'openid profile' }
  },
  google: {
    preferredConnectionId: 'google',
    enableUsingAuthressDevelopmentIdentity: true,
    showTenantAssignmentTypeSwitcher: true,
    brandIcon: GoogleBrandIcon,
    authenticationUrl: 'https://accounts.google.com/o/oauth2/v2/auth', tokenUrl: 'https://oauth2.googleapis.com/token',
    helpLink: 'https://support.google.com/cloud/answer/6158849?hl=en', helpText: 'Google Oauth 2.0 app guide',
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-google-login',
    data: { name: 'Google' }
  },
  con_facebook: {
    enableUsingAuthressDevelopmentIdentity: true,
    brandIcon: FacebookBrandIcon,
    // https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow/
    authenticationUrl: 'https://www.facebook.com/v19.0/dialog/oauth', tokenUrl: 'https://graph.facebook.com/oauth/access_token',
    helpLink: 'https://developers.facebook.com/docs/development/create-an-app/facebook-login-use-case', helpText: 'Meta Developers guide',
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-facebook-login',
    data: { name: 'Facebook', userIdExpression: '{data.id}', oidcUserEndpointUrl: 'https://graph.facebook.com/me' },
    defaultConnectionProperties: { scope: 'email public_profile' }
  },
  con_linkedin: {
    brandIcon: LinkedInBrandIcon,
    // https://www.linkedin.com/oauth/.well-known/openid-configuration
    authenticationUrl: 'https://www.linkedin.com/oauth/v2/authorization', tokenUrl: 'https://www.linkedin.com/oauth/v2/accessToken',
    helpLink: 'https://developers.facebook.com/docs/development/create-an-app/facebook-login-use-case', helpText: 'LinkedIn OAuth',
    authressSetupGuide: '',
    data: { name: 'LinkedIn', supportedContentType: 'application/x-www-form-urlencoded' }
    // The discovery document says the sub claim is supported, but https://learn.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?tabs=HTTPS1#response suggests that there is no id token.
    // data: { name: 'LinkedIn', supportedContentType: 'application/x-www-form-urlencoded', userIdExpression: '{data.sub}', oidcUserEndpointUrl: 'https://api.linkedin.com/v2/userinfo' }
  },
  microsoft: {
    enableUsingAuthressDevelopmentIdentity: true,
    brandIcon: MicrosoftBrandIcon,
    authenticationUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize', tokenUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
    helpLink: 'https://docs.microsoft.com/en-us/azure/active-directory/develop/quickstart-register-app', helpText: 'Microsoft app guide',
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-azure-ad-connection',
    data: { name: 'Microsoft', userIdExpression: '{oid}', supportedContentType: 'application/x-www-form-urlencoded' }
  },
  github: {
    enableUsingAuthressDevelopmentIdentity: true,
    brandIcon: GithubBrandIcon,
    authenticationUrl: 'https://github.com/login/oauth/authorize', tokenUrl: 'https://github.com/login/oauth/access_token',
    helpLink: 'https://github.com/settings/developers', helpText: 'GitHub applications',
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-github-oauth',
    data: { name: 'GitHub', userIdExpression: '{data.id}', oidcUserEndpointUrl: 'https://api.github.com/user', supportedContentType: 'application/json' },
    defaultConnectionProperties: {
      scope: 'openid profile email read:user user:email'
    }
  },
  gitlab: {
    enableUsingAuthressDevelopmentIdentity: true,
    brandIcon: GitlabBrandIcon,
    authenticationUrl: 'https://gitlab.com/oauth/authorize', tokenUrl: 'https://gitlab.com/oauth/token',
    helpLink: 'https://docs.gitlab.com/ee/integration/oauth_provider.html', helpText: 'GitLab OAuth provider',
    authressSetupGuide: '',
    data: { name: 'GitLab', userIdExpression: '{sub}', oidcUserEndpointUrl: 'https://gitlab.com/oauth/userinfo', supportedContentType: 'application/json' }, defaultConnectionProperties: { prompt: '' }
  },
  procore: {
    brandIcon: ProcoreBrandIcon,
    authenticationUrl: 'https://login.procore.com/oauth/authorize', tokenUrl: 'https://login.procore.com/oauth/token',
    helpLink: 'https://developers.procore.com/documentation/building-apps-create-new', helpText: 'Procore new applications',
    authressSetupGuide: '',
    data: { name: 'Procore', userIdExpression: '{data.id}', oidcUserEndpointUrl: 'https://api.procore.com/rest/v1.0/me', supportedContentType: 'application/x-www-form-urlencoded' }, defaultConnectionProperties: { scope: '' }
  },
  cognito: {
    brandIcon: CognitoBrandIcon,
    helpLink: 'https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-as-user-directory.html',
    helpText: 'Cognito user pools',
    authressSetupGuide: '',
    data: { name: 'Cognito', supportedContentType: 'application/x-www-form-urlencoded' }
  },
  slack: {
    brandIcon: SlackBrandIcon,
    authenticationUrl: 'https://slack.com/oauth/v2/authorize', tokenUrl: 'https://slack.com/api/oauth.v2.access',
    helpLink: 'https://api.slack.com/authentication/oauth-v2', helpText: 'Slack OAuth',
    authressSetupGuide: '',
    data: { name: 'Slack', userIdExpression: 'slack|{team.id}|{authed_user.id}', oidcUserEndpointUrl: 'https://slack.com/api/users.info?user={authed_user.id}', trustIdentityUserId: true, supportedContentType: 'application/x-www-form-urlencoded' },
    defaultConnectionProperties: { scope: 'chat:write,chat:write.public,commands,im:read,im:write' }
  },
  con_salesforce: {
    brandIcon: SalesforceBrandIcon,
    authenticationUrl: 'https://login.salesforce.com/services/oauth2/authorize', tokenUrl: 'https://login.salesforce.com/services/oauth2/token',
    helpLink: 'https://developer.salesforce.com/docs/atlas.en-us.api_rest.meta/api_rest/intro_oauth_and_connected_apps.htm', helpText: "Salesforce's Connected Apps",
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-salesforce-connected-apps',
    data: { name: 'Salesforce', supportedContentType: 'application/x-www-form-urlencoded' }
  },
  con_oracle_netsuite: {
    brandIcon: OracleNetsuiteBrandIcon,
    authenticationUrl: 'https://system.netsuite.com/app/login/oauth2/authorize.nl', tokenUrl: 'https://system.netsuite.com/services/rest/auth/oauth2/v1/token',
    displayName: 'Oracle NetSuite ERP',
    helpLink: 'https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_157771733782.html#procedure_157838925981', helpText: 'NetSuite OAuth 2.0',
    authressSetupGuide: '',
    data: { name: 'NetSuite', supportedContentType: 'application/x-www-form-urlencoded', oidcUserEndpointUrl: '', userIdExpression: '{sub}' },
    defaultConnectionProperties: { scope: 'openid email restlets rest_webservices suite_analytics' }
  },
  con_hubspot: {
    brandIcon: HubspotBrandIcon,
    authenticationUrl: 'https://app.hubspot.com/oauth/authorize', tokenUrl: 'https://api.hubapi.com/oauth/v1/token',
    helpLink: 'https://developers.hubspot.com/docs/api/working-with-oauth', helpText: 'HubSpot OAuth 2.0 Integration',
    authressSetupGuide: '',
    data: { name: 'HubSpot', supportedContentType: 'application/x-www-form-urlencoded', oidcUserEndpointUrl: 'https://api.hubapi.com/oauth/v1/access-tokens/{access_token}',
      userIdExpression: 'hub|{data.hub_id}|${data.user_id}' },
    defaultConnectionProperties: { scope: 'oauth' }
  },
  con_stripe: {
    brandIcon: StripeBrandIcon,
    authenticationUrl: 'https://marketplace.stripe.com/oauth/v2/com.example.oauth/authorize', tokenUrl: 'https://api.stripe.com/v1/oauth/token',
    helpLink: 'https://stripe.com/docs/stripe-apps/api-authentication/oauth', helpText: 'Stripe Platform Oauth',
    authressSetupGuide: '',
    data: { name: 'Stripe', supportedContentType: 'application/x-www-form-urlencoded' }
  },
  steam: {
    brandIcon: SteamBrandIcon, type: 'OPENID-LEGACY',
    authenticationUrl: 'https://steamcommunity.com/openid/login?openid.ns=http://specs.openid.net/auth/2.0&openid.mode=checkid_setup&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select',
    authressSetupGuide: '',
    helpLink: 'https://steamcommunity.com/dev', helpText: 'Steam Publishing',
    data: {
      name: 'Steam',
      supportedContentType: 'application/x-www-form-urlencoded',
      // oidcUserEndpointUrl_bak: 'https://steamcommunity.com/oauth/userinfo', https://api.steampowered.com/ISteamUser/GetPlayerSummaries/v0002
      // IMPORTANT: This should never be set to true until we have correct token verification in place for incoming openid tokens
      trustIdentityUserId: false
    },
    defaultConnectionProperties: { scope: 'profile openid' }
  },
  con_yahoo: {
    enableUsingAuthressDevelopmentIdentity: false,
    brandIcon: YahooBrandIcon,
    authenticationUrl: 'https://api.login.yahoo.com/oauth2/request_auth', tokenUrl: 'https://api.login.yahoo.com/oauth2/get_token',
    helpLink: 'https://developer.yahoo.com/sign-in-with-yahoo', helpText: 'Yahoo developer network registration',
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-yahoo-login',
    data: { name: 'Yahoo!', supportedContentType: 'application/x-www-form-urlencoded' }, defaultConnectionProperties: { scope: 'profile email openid' }
  },
  zoho: {
    enableUsingAuthressDevelopmentIdentity: true,
    brandIcon: ZohoBrandIcon,
    authenticationUrl: 'https://accounts.zoho.com/oauth/v2/auth', tokenUrl: '{accounts-server}/oauth/v2/token',
    helpLink: 'https://www.zoho.com/accounts/protocol/oauth-setup.html', helpText: 'Zoho client registration',
    authressSetupGuide: 'https://authress.io/knowledge-base/docs/authentication/connecting-providers-idp/setup-zoho-oauth',
    data: { name: 'Zoho', supportedContentType: 'application/x-www-form-urlencoded' }, defaultConnectionProperties: { scope: 'email', prompt: '' }
  },
  // Tester: https://rp.sandbox.pre.swissid.ch/oidc/
  con_swissid: {
    brandIcon: SwissIdBrandIcon,
    // https://login.swissid.ch/idp/oauth2/.well-known/openid-configuration
    authenticationUrl: 'https://login.swissid.ch/idp/oauth2/authorize', tokenUrl: 'https://login.swissid.ch/idp/oauth2/access_token',
    helpLink: 'https://www.swissid.ch/dam/jcr:471f63c6-606e-4c04-be02-afc99f4d2612/OIDC_IGL_for_RPs_4.7.pdf', helpText: 'Swiss ID OpenID Guide',
    authressSetupGuide: '',
    data: { name: 'Swiss ID', supportedContentType: 'application/x-www-form-urlencoded'
      // oidcUserEndpointUrl: 'https://login.swissid.ch/idp/oauth2/userinfo',
      // userIdExpression: 'hub|{data.hub_id}|${data.user_id}'
    },
    defaultConnectionProperties: { scope: 'openid email', prompt: 'login' }
  }
};

export { preferredConnections };

export default class PreferredConnectionProvider {
  constructor(loginClient) {
    this.loginClient = loginClient;
    this.preferredConnections = preferredConnections;
  }

  async create(connectionId, autoEnableAuthressCredentials) {
    const configuration = preferredConnections[connectionId];
    const connectionData = {
      connectionId: connectionId,
      type: configuration.type || 'OAUTH2',
      tokenUrl: configuration.tokenUrl,
      clientId: autoEnableAuthressCredentials && configuration.enableUsingAuthressDevelopmentIdentity ? 'authress' : null,
      clientSecret: null,
      authenticationUrl: configuration.authenticationUrl,
      issuerUrl: null, providerCertificate: null,
      data: configuration.data || {},
      defaultConnectionProperties: configuration.defaultConnectionProperties
    };
    const result = await this.loginClient.createConnection(connectionData);
    return result;
  }
}
