import 'error-object-polyfill';

import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueScrollTo from 'vue-scrollto';
import VueClipboard from 'vue-clipboard2';

import BuildInfo from './buildInfo';
import alertHandler from './errorHandling/alertHandler';

require('./components/shared/responsive-toolkit');
import routes from './routes';
import store from './store';

import logger from './clients/logger';
import fullStory from './clients/fullStory';
import registerHandlers from './errorHandling/registerHandlers';
import OnCreatedComponentMixinProvider from './components/onCreatedComponentMixinProvider';
import platformClient from './clients/platformClient';
import authManager from './auth/authManager.js';
import LoginClient from './clients/loginClient';
import AuthressClient from './clients/authressClient';
import AuthressProvider from './clients/authressProvider';
import PreferredConnectionProvider from './clients/preferredConnectionProvider';
import BillingClient from './clients/billingClient';
import cursorHandler from './components/shared/cursorHandler';
import ResponsiveBootstrapToolkit from './components/shared/responsive-toolkit';

if (window.location.href.match('^https://(authress.com|api-cf-[a-z-]+.authress.io)')) {
  window.location.hostname = 'authress.io';
}

// eslint-disable-next-line no-console
console.log(`%cWelcome to Authress!%c

You found us! Does this page need fixes or improvements? Open an issue or report it directly to our development team. Everyone can contribute!

🤝 Contact the Authress development team: developers@authress.io
🔎 Create an issue: https://github.com/Authress/authress.github.io/issues
🚀 Join the Authress security community: https://authress.io/community
`, 'padding-top: 0.5em; font-size: 2em; color: #38b3f9', 'padding-bottom: 0.5em;');

Vue.use(VueRouter);
Vue.use(VueScrollTo);
// https://www.npmjs.com/package/vue-clipboard2#it-doesnt-work-with-bootstrap-modals
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

import { BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupText, BContainer, BButton, ButtonPlugin, BVConfigPlugin, BTooltip, BLink } from 'bootstrap-vue';
Vue.component('BButton', BButton);
Vue.use(ButtonPlugin, { BButton: { variant: 'primary' } });
Vue.component('BContainer', BContainer);
Vue.component('BTooltip', BTooltip);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupText', BInputGroupText);
Vue.component('BLink', BLink);

import CopyInput from './components/shared/copyInput';
Vue.component('CopyInput', CopyInput);
Vue.use(BVConfigPlugin, {
  BFormText: { textVariant: 'default' }
});

import Words from './components/shared/words';
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Words', Words);

import ResourceUri from './components/shared/resourceUri.vue';
Vue.component('ResourceUri', ResourceUri);

import Required from './components/shared/required';
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Required', Required);

import Loader from './components/shared/loader';
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Loader', Loader);

import ExternalLink from './components/shared/externalLink';
Vue.component('ExternalLink', ExternalLink);

import MissingUIAccess from './components/shared/missingUIAccess';
Vue.component('MissingUiAccess', MissingUIAccess);

import ActivityButton from './components/shared/activityButton';

Vue.component('ActivityButton', ActivityButton);

const App = () => import(/* webpackChunkName: "main" */ './app.vue');

function init() {
  store.commit('initialize');

  logger.initialize();

  const appInitializer = () => {};
  Vue.mixin(new OnCreatedComponentMixinProvider().getMixin(logger, appInitializer));

  const authressClient = new AuthressClient(platformClient, logger);
  const loginClient = new LoginClient(authressClient, platformClient);
  const authressProvider = new AuthressProvider(logger, store, authressClient);
  const preferredConnectionProvider = new PreferredConnectionProvider(loginClient);
  const billingClient = new BillingClient(logger, platformClient);

  const router = new VueRouter({
    routes: routes(authressProvider),
    store,
    linkActiveClass: 'active'
  });

  logger.getUserData = () => authManager.getExistingUserIdentity();
  authManager.addLoginHook(async () => {
    try {
      fullStory.sessionIdentify();
      await authressProvider.fetchAccounts({ allowAnyCurrentAccount: true });
      logger.debug({ title: 'User Accounts identified', accounts: store.state.cache.accounts.map(a => a.accountId) });
      fullStory.setAccountId(store.getters.currentAccount?.accountId);
      await authressProvider.hasAccessToUseAuthressUI();
    } catch (error) {
      logger.error({ title: 'Failed to complete login for user', error });
    }
  });

  logger.getRoute = () => ({
    fullPath: router.currentRoute.fullPath
  });

  logger.log({ title: 'Version', version: BuildInfo.version }, true);
  registerHandlers(Vue, window, router);

  // ensures that remaining messages are sent to SumoLogic in case the user navigates away from the page
  window.onbeforeunload = () => {
    logger.flushOnUnload();
  };

  function captureReferredData() {
    try {
      const replacement = new URL(window.location.href);
      const setReferrerData = {
        source: replacement.searchParams.get('utm_source'),
        medium: replacement.searchParams.get('utm_medium'),
        name: replacement.searchParams.get('utm_campaign'),
        content: replacement.searchParams.get('utm_content')
      };
      replacement.searchParams.delete('utm_source');
      replacement.searchParams.delete('utm_medium');
      replacement.searchParams.delete('utm_campaign');
      replacement.searchParams.delete('utm_content');
      if (replacement.toString() !== window.location.href) {
        store.commit('setReferrerData', setReferrerData);
        window.history.replaceState({}, document.title, replacement.toString());
      }
    } catch (error) { /* */ }
  }

  new Vue({
    router,
    store,

    provide: {
      alertHandler,
      logger,
      authManager,
      loginClient,
      authressClient,
      authressProvider,
      billingClient,
      preferredConnectionProvider
    },

    created() {
      fullStory.setKeys(logger.sessionKey, BuildInfo.version.releaseDate);
      fullStory.sessionIdentify();
      captureReferredData();
      router.beforeResolve((to, from, next) => {
        store.commit('setGlobalLoading', false);
        next();
      });
      router.afterEach(to => {
        document.activeElement.blur();
        document.title = this.getDocumentTitle(to);
        cursorHandler.setLoading(false);
        alertHandler.removeAlert('danger', false);
        alertHandler.removeAlert('warning', false);
        // Don't execute in mobile in webkit, it can cause the navbar to be hidden under the address bar
        if (ResponsiveBootstrapToolkit.md || ResponsiveBootstrapToolkit.lg || ResponsiveBootstrapToolkit.xl) {
          try {
            document.getElementById('content-container').scrollIntoView(true);
          } catch (error) {
            window.scrollTo(0, 0);
          }
        }
      });
      document.title = this.getDocumentTitle(this.$route);
    },
    watch: {
      // watch any changes to the route and ensure permissions
      $route(newRoute) {
        const setToTrue = newRoute.query.dev === 'true';
        const setValue = setToTrue || newRoute.query.dev === 'false';
        if (setValue) {
          store.commit('setAppDevelopmentVersion', setToTrue);
        }
      }
    },

    methods: {
      getDocumentTitle(route = null) {
        if (!route || !route.name) {
          return 'Authress';
        }

        return `Authress - ${route.meta?.name || route.name}`;
      }
    },
    render: h => h(App)
  }).$mount('#app');
}

init();
