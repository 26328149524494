import shortUuid from 'short-uuid';

import fullStory from './fullStory';
import store from '../store';
import logger from '../clients/logger';
import { cloneDeep } from 'lodash';
import auditEventRepository from '@/components/audit/auditEventRepository';

export default class AuthressProvider {
  constructor(_, __, authressClient) {
    auditEventRepository.initialize();

    this.authressClient = authressClient;
    this.fetchAccountsPromise = null;

    this.recordPointerUrl = null;
    this.groupPointerUrl = null;
    this.alertPointerUrl = null;

    store.watch((state, getters) => getters.currentAccount, (newAccount, oldAccount) => {
      if (newAccount && newAccount?.accountId !== oldAccount?.accountId) {
        this.setDomain(newAccount?.domain, newAccount?.region);
      }
    });
  }

  setDomain(domain, region) {
    this.authressClient.setDomain(domain, region);
  }

  async fetchAccount() {
    if (!store.getters.currentAccount) {
      logger.log({ title: 'Attempting to fetch account, when no accounts are stored, forcing a refresh of the whole cache', level: 'TRACK', cacheAccounts: store.state.cache.accounts });
      await this.fetchAccounts({ forceRefresh: true });
      return;
    }
    const account = await this.authressClient.getAccount(store.getters.currentAccount.accountId);
    store.commit('updateAccount', account);
    this.setDomain(store.getters.currentAccount.domain, store.getters.currentAccount.region);
  }

  fetchAccounts(options = { allowAnyCurrentAccount: false, forceRefresh: false, includeUpdate: false }) {
    const handler = async () => {
      try {
        const accountLists = this.authressClient.getAccounts(store.state.permanentCache.lastLogout);

        const accountListsAsync = Promise.all(accountLists.map(async accountsAsync => {
          const accounts = await accountsAsync;
          store.commit('setAccounts', accounts);
          return accounts;
        })).then(allAccountLists => {
          store.commit('restrictAccountList', allAccountLists.flat(1));
          fullStory.setAccountId(store.getters.currentAccount?.accountId);
        });

        if (options.allowAnyCurrentAccount) {
          const accountFound = async () => {
            for (let iteration = 0; iteration < 200 && !store.getters.currentAccount; iteration++) {
              await new Promise(resolve => setTimeout(resolve, 50));
            }
            if (store.getters.currentAccount) {
              return store.getters.currentAccount;
            }
            return new Promise(() => {});
          };
          await Promise.race([accountFound(), accountListsAsync]);
        } else {
          await accountListsAsync;
        }
        this.setDomain(store.getters.currentAccount?.domain, store.getters.currentAccount?.region);
      } catch (error) {
        logger.error({ title: 'Failed to fetch accounts', options, error });
      }
    };

    // If there isn't already a fetch for this session start one.
    if (!this.fetchAccountsPromise) {
      this.fetchAccountsPromise = handler();
    }

    if (options.allowAnyCurrentAccount && store.getters.currentAccount && !options.forceRefresh) {
      this.setDomain(store.getters.currentAccount?.domain, store.getters.currentAccount?.region);

      if (options.includeUpdate) {
        this.fetchAccountsPromise.then(async () => {
          try {
            await this.fetchAccount();
          } catch (error) {
            if (error.status !== 401 && error.status !== 404) {
              logger.error({ title: 'Failed to get updates for account', error });
            }
          }
        });
      }
      return Promise.resolve();
    }

    if (this.fetchAccountsPromise && !options.forceRefresh) {
      return this.fetchAccountsPromise;
    }

    // Otherwise we are forcing a refresh, so replace the handler and await it.
    return this.fetchAccountsPromise = handler();
  }

  async ensureAccount(accountData, forceCreation, acceptTermsAndConditions = true) {
    await this.fetchAccounts();
    if (!store.getters.currentAccount || forceCreation) {
      try {
        const account = await this.authressClient.createAccount(accountData, acceptTermsAndConditions);
        store.commit('createAccount', account);
        this.setDomain(account?.domain, account?.region);
        fullStory.setAccountId(account?.accountId);
      } catch (error) {
        logger.warn({ title: 'Failed to create a new account', accountData, cacheAccounts: store.state.cache.accounts, error });
        throw error;
      }
    }
  }

  async updateAccount(accountData) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', accountData, cacheAccounts: store.state.cache.accounts });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    const account = await this.authressClient.updateAccount(accountId, accountData);
    store.commit('updateAccount', account);
    fullStory.setAccountId(account?.accountId);
    this.setDomain(account?.domain, account?.region);
  }

  async updateAccountSsoConfiguration(ssoConfiguration) {
    if (!store.getters.currentAccount?.accountId) {
      logger.log({ title: 'Current account is not set, The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page',
        level: 'ERROR', ssoConfiguration, cacheAccounts: store.state.cache.accounts });
      throw Error.create({ title: 'The currentAccount is not set in the Authress Management Portal, potentially we need to force the user to reload the page' });
    }

    const accountId = store.getters.currentAccount.accountId;
    await this.authressClient.updateAccountSsoConfiguration(accountId, ssoConfiguration);
    const newAccountData = Object.assign(cloneDeep(store.getters.currentAccount), ssoConfiguration);
    store.commit('updateAccount', newAccountData);
  }

  async setAccount(accountId) {
    store.commit('setGlobalLoading', true);
    let account = store.state.cache.accounts.find(acc => acc.accountId === accountId);
    if (!account) {
      try {
        account = await this.authressClient.getAccount(accountId);
      } catch (error) {
        store.commit('setGlobalLoading', false);
        return false;
      }
      // Enable this value to be saved across reloads
      store.commit('updateAccount', { ...account, persist: true });
    } else {
      // Force showing a loader on the screen
      await new Promise(resolve => setTimeout(resolve, 300));
    }

    this.setDomain(account.domain, account.region);
    store.commit('setCurrentAccount', account.accountId);
    fullStory.setAccountId(account?.accountId);
    window.location.reload();
    store.commit('setGlobalLoading', false);
    return true;
  }

  requireAccount() {
    return async (to, from, next) => {
      await this.fetchAccounts({ allowAnyCurrentAccount: true });
      if (store.getters.currentAccount) {
        next();
        return;
      }

      next({ name: 'Signup' });
    };
  }

  async acceptInvite(inviteId) {
    const account = await this.authressClient.acceptInvite(inviteId);
    store.commit('updateAccount', account);
    this.setDomain(account?.domain, account?.region);
    fullStory.setAccountId(account?.accountId);
  }

  async hasAccessToUseAuthressUI() {
    if (!store.getters.currentAccount || !store.state.permanentCache.authressDomain || store.state.hasAccessToUI) {
      store.commit('setAccessToUI', true);
      return;
    }
    const readConfigurationAsync = this.authressClient.validateUserAuthorization(store.state.profile.userId, 'Authress:Configuration/Account', 'READ');
    const readRolesAsync = this.authressClient.validateUserAuthorization(store.state.profile.userId, 'Authress:Roles', 'READ');

    try {
      const [readConfig, readRoles] = await Promise.all([readConfigurationAsync, readRolesAsync]);
      store.commit('setAccessToUI', readConfig || readRoles);
    } catch (error) {
      if (error.status === 401) {
        store.commit('setAccessToUI', false);
        return;
      }
      logger.error({ title: 'Failed to check if user has access to Authress UI functionality', error });
    }
  }

  async fetchAllRoles() {
    try {
      const roles = [];

      let response = await this.authressClient.getRoleListResponse();
      roles.push(...response.roles);
      while (response.links?.next?.href) {
        response = await this.authressClient.getPaginationData(response.links?.next?.href);
        roles.push(...response.roles);
      }
      if (roles.length > 500) {
        logger.log({ title: 'Total number of roles is greater than 500, optimize role display screen to only show the first matching 100 and filter using the API',
          level: 'TRACK', roleCount: roles.length });
      }
      store.commit('setRoles', roles);
    } catch (error) {
      if (error.status === 403 || error.status === 404) {
        logger.log({ title: 'User does not have access to roles', error });
        return;
      }
      logger.error({ title: 'Failed to get roles', error });
    }
  }

  async createRole(roleRequest) {
    const role = cloneDeep(roleRequest);
    if (!role.roleId) {
      role.roleId = `ro_${shortUuid('abcdefghijkmnopqrstuvwxyz023456789').generate()}`;
    }

    const filteredRole = {
      roleId: role.roleId, name: role.name || role.roleId, description: role.description,
      permissions: role.permissions.filter(p => p.action).map(p => ({ action: p.action, allow: p.types.allow, grant: p.types.grant, delegate: p.types.delegate }))
    };
    const roleData = await this.authressClient.createRole(role.roleId, filteredRole);
    store.commit('setRole', { roleId: roleData.roleId, role: filteredRole });
    return roleData;
  }

  async updateRole(role) {
    const filteredRole = {
      roleId: role.roleId, name: role.name || role.roleId, description: role.description,
      permissions: role.permissions.filter(p => p.action).map(p => ({ action: p.action, allow: p.types.allow, grant: p.types.grant, delegate: p.types.delegate }))
    };
    await this.authressClient.putRole(role.roleId, filteredRole);
    store.commit('setRole', { roleId: role.roleId, role: filteredRole });
  }

  async deleteRole(roleId) {
    await this.authressClient.deleteRole(roleId);
    store.commit('setRole', { roleId, role: null });
  }

  async fetchRecord(recordId, options = { includeDeleted: false }) {
    try {
      const record = await this.authressClient.getRecord(recordId);
      store.commit('setRecord', { recordId, record });
      store.commit('cacheTags', record?.tags);
      store.commit('cacheResources', record?.statements?.map(s => s.resources?.map(re => re.resourceUri)));
      return record;
    } catch (error) {
      if (error.status === 404) {
        logger.info({ title: 'Error fetching record, because it is no longer found', recordId, error });
        const existingRecord = store.state.cache.records.find(r => r.recordId === recordId);
        if (existingRecord) {
          existingRecord.status = 'DELETED';
          store.commit('setRecord', { recordId, record: existingRecord });
        }
        return options?.includeDeleted ? existingRecord : null;
      }
      logger.warn({ title: 'Error fetching record', recordId, error });
      return store.state.cache.records.find(r => r.recordId === recordId);
    }
  }

  async fetchRecords(getNextPage, filter) {
    try {
      let response;
      if (getNextPage) {
        response = await this.authressClient.getPaginationData(this.recordPointerUrl);
      } else {
        response = await this.authressClient.getRecords(filter);
      }

      if (!response.records) {
        return false;
      }
      store.commit('setRecords', response.records);
      store.commit('cacheTags', response.records.map(r => r.tags));
      store.commit('cacheResources', response.records.map(r => r.statements?.map(s => s.resources?.map(re => re.resourceUri))));

      this.recordPointerUrl = response.links?.next?.href;
      if (!this.recordPointerUrl) {
        return false;
      }
    } catch (error) {
      logger.warn({ title: 'Error fetching records', error });
    }

    return true;
  }

  async fetchClient(clientId) {
    const client = await this.authressClient.getClient(clientId);
    store.commit('setClient', { clientId, client });
    store.commit('cacheTags', client?.tags);
    return client;
  }

  async fetchClients() {
    const clients = [];
    try {
      let response = await this.authressClient.getClients();
      clients.push(...response.clients);
      for (let iteration = 0; iteration < 10 && response.links?.next?.href; iteration++) {
        response = await this.authressClient.getPaginationData(response.links?.next?.href);
        clients.push(...response.clients);
      }
      store.commit('setClients', clients);
      store.commit('cacheTags', clients.map(c => c.tags));
      if (clients.length > 500) {
        logger.error({ title: `Paging UX for clients in the UI must be set up. Current length: ${clients.length}` });
      }

      return clients;
    } catch (error) {
      logger.warn({ title: 'Error fetching clients', error });
      return store.state.cache.clients;
    }
  }

  async createClient(clientData) {
    const client = await this.authressClient.createClient(clientData);
    store.commit('setClient', { clientId: client.clientId, client });
    store.commit('cacheTags', clientData.tags);
    return client;
  }

  async updateClient(clientId, newClient) {
    const allowedProperties = {
      clientId, name: newClient.name, tags: newClient.tags, options: newClient.options, rateLimits: newClient.rateLimits
    };
    await this.authressClient.updateClient(clientId, allowedProperties);
    store.commit('setClient', { clientId, client: newClient });
    store.commit('cacheTags', newClient.tags);
    return newClient;
  }

  async removeClient(clientId) {
    await this.authressClient.removeClient(clientId);
    store.commit('setClient', { clientId });
  }

  async createRecord(record) {
    const filteredRecord = {
      recordId: record.recordId || undefined, name: record.name, description: record.description, statements: record.statements, users: record.users, admins: record.admins,
      groups: record.groups, tags: record.tags
    };
    const createdRecord = await this.authressClient.createRecord(filteredRecord);
    store.commit('setRecord', { recordId: null, record: createdRecord });
    store.commit('cacheTags', record.tags);
    store.commit('cacheResources', record?.statements?.map(s => s.resources?.map(re => re.resourceUri)));
    return createdRecord;
  }

  async updateRecord(record) {
    const filteredRecord = {
      recordId: record.recordId, name: record.name, description: record.description, statements: record.statements, users: record.users, admins: record.admins, groups: record.groups, tags: record.tags
    };

    await this.authressClient.putRecord(record.recordId, filteredRecord, record.lastUpdated);
    store.commit('setRecord', { recordId: record.recordId, record: filteredRecord });
    store.commit('cacheTags', record.tags);
    store.commit('cacheResources', record?.statements?.map(s => s.resources?.map(re => re.resourceUri)));
    return filteredRecord;
  }

  async deleteRecord(recordId) {
    await this.authressClient.deleteRecord(recordId);
    store.commit('setRecord', { recordId, record: null });
  }

  /* GROUPS */
  async fetchAllGroups(filter) {
    let response = await this.authressClient.getGroups(filter);
    store.commit('setGroups', response.groups);
    while (response.links?.next?.href) {
      response = await this.authressClient.getPaginationData(response.links?.next?.href);
      store.commit('setGroups', response.groups);
    }
  }

  async fetchGroups(getNextPage, filter) {
    try {
      let response;
      if (getNextPage) {
        response = await this.authressClient.getPaginationData(this.groupPointerUrl);
      } else {
        response = await this.authressClient.getGroups(filter);
      }

      if (!response.groups) {
        return false;
      }

      store.commit('setGroups', response.groups);
      store.commit('cacheTags', response.groups.map(g => g.tags));
      this.groupPointerUrl = response.links?.next?.href;
      if (!this.groupPointerUrl) {
        return false;
      }
    } catch (error) {
      logger.warn({ title: 'Error fetching groups', error });
    }

    return true;
  }

  async fetchGroup(groupId) {
    try {
      const group = await this.authressClient.getGroup(groupId);
      store.commit('setGroup', { groupId, group });
      store.commit('cacheTags', group?.tags);
      return group;
    } catch (error) {
      logger.warn({ title: 'Error fetching group', groupId, error });
      return null;
    }
  }

  async createGroup(group) {
    const filteredGroup = { groupId: group.groupId || undefined, name: group.name, users: group.users, admins: group.admins, tags: group.tags };
    const createdGroup = await this.authressClient.createGroup(filteredGroup);
    store.commit('setGroup', { groupId: null, group: createdGroup });
    store.commit('cacheTags', group.tags);
    return createdGroup;
  }

  async updateGroup(group) {
    const filteredGroup = { groupId: group.groupId, name: group.name, users: group.users, admins: group.admins, tags: group.tags };
    await this.authressClient.putGroup(group.groupId, filteredGroup);
    store.commit('setGroup', { groupId: group.groupId, group: filteredGroup });
    store.commit('cacheTags', group.tags);
    return filteredGroup;
  }

  async deleteGroup(groupId) {
    await this.authressClient.deleteGroup(groupId);
    store.commit('setGroup', { groupId, group: null });
  }

  /** ******** ALERTS *************/

  async fetchAlerts(getNextPage, filter) {
    try {
      let response;
      if (getNextPage) {
        response = await this.authressClient.getPaginationData(this.alertPointerUrl);
      } else {
        response = await this.authressClient.getAlerts(filter);
      }

      if (!response.alerts) {
        return false;
      }

      store.commit('setAlerts', response.alerts);
      this.alertPointerUrl = response.links?.next?.href;
      if (!this.alertPointerUrl) {
        return false;
      }
    } catch (error) {
      logger.warn({ title: 'Error fetching alerts', error });
    }

    return true;
  }

  async fetchAlert(alertId) {
    try {
      const alert = await this.authressClient.getAlert(alertId);
      store.commit('setAlert', { alertId, alert });
      return alert;
    } catch (error) {
      logger.warn({ title: 'Error fetching alert', alertId, error });
      return null;
    }
  }

  async createAlert(alert) {
    const filteredAlert = { name: alert.name, value: alert.value, type: alert.type };
    const createdAlert = await this.authressClient.createAlert(filteredAlert);
    store.commit('setAlert', { alertId: null, alert: createdAlert });
    return createdAlert;
  }

  async updateAlert(alertId, alert) {
    const filteredAlert = { name: alert.name, value: alert.value, type: alert.type };
    await this.authressClient.putAlert(alertId, filteredAlert);
    store.commit('setAlert', { alertId: alertId, alert: filteredAlert });
    return filteredAlert;
  }

  async deleteAlert(alertId) {
    await this.authressClient.deleteAlert(alertId);
    store.commit('setAlert', { alertId, alert: null });
  }

  /** ******** EXTENSIONS *************/

  async fetchExtensions(getNextPage, filter) {
    try {
      let response;
      if (getNextPage) {
        response = await this.authressClient.getPaginationData(this.extensionPointerUrl);
      } else {
        response = await this.authressClient.getExtensions(filter);
      }

      if (!response.extensions) {
        return false;
      }
      store.commit('setExtensions', response.extensions);
      this.extensionPointerUrl = response.links?.next?.href;
      if (!this.extensionPointerUrl) {
        return false;
      }
    } catch (error) {
      logger.warn({ title: 'Error fetching extensions', error });
    }

    return true;
  }

  async fetchExtension(extensionId) {
    try {
      const extension = await this.authressClient.getExtension(extensionId);
      store.commit('setExtension', { extensionId, extension });
    } catch (error) {
      logger.warn({ title: 'Error fetching extension', extensionId, error });
    }
  }

  async createExtension(extension) {
    const filteredExtension = { extensionId: extension.extensionId || undefined, name: extension.name };
    const createdExtension = await this.authressClient.createExtension(filteredExtension);
    store.commit('setExtension', { extensionId: null, extension: createdExtension });
    return createdExtension;
  }

  async updateExtension(extension) {
    const filteredExtension = { extensionId: extension.extensionId, name: extension.name };
    await this.authressClient.putExtension(extension.extensionId, filteredExtension);
    store.commit('setExtension', { extensionId: extension.extensionId, extension: filteredExtension });
    return filteredExtension;
  }

  async deleteExtension(extensionId) {
    await this.authressClient.deleteExtension(extensionId);
    store.commit('setExtension', { extensionId, extension: null });
  }

  /** ******** REQUESTS *************/

  async fetchRequests() {
    try {
      const requests = await this.authressClient.getRequests();
      store.commit('setRequests', requests);
    } catch (error) {
      if (error.status === 403 || error.status === 404) {
        logger.log({ title: 'User does not have access to requests', error });
        return;
      }
      logger.error({ title: 'Failed to get requests', error });
    }
  }

  async fetchRequest(requestId) {
    try {
      const request = await this.authressClient.getRequest(requestId);
      store.commit('setRequest', { requestId, request });
      return request;
    } catch (error) {
      if (error.status === 403 || error.status === 404) {
        logger.log({ title: 'User does not have access to request', requestId, error });
        store.commit('setRequest', { requestId, request: null });
        return null;
      }
      logger.error({ title: 'Failed to get request', requestId, error });
      return null;
    }
  }

  async updateRequest(requestId, status) {
    const request = store.state.cache.requests.find(r => r.requestId === requestId);
    const patchedRequest = {
      ...request,
      status
    };
    await this.authressClient.patchRequest(requestId, status);
    store.commit('setRequest', { requestId: request.requestId, request: patchedRequest });
  }

  fetchAccountUsage(forceRefresh = false) {
    if (this.fetchUsageAsync && !forceRefresh) {
      return this.fetchUsageAsync;
    }

    const fetch = async () => {
      await this.fetchAccounts({ allowAnyCurrentAccount: true });
      const accountId = store.getters?.currentAccount?.accountId;
      if (!accountId) {
        return;
      }

      try {
        const usageData = await this.authressClient.getAccountUsage(accountId);
        store.commit('setUsage', { usage: usageData.usage, history: usageData.history });
      } catch (error) {
        if (error.status !== 401 && error.status !== 404 && error.status !== 403) {
          logger.error({ title: 'Failed to get account billing usage', error, accountId });
        }
        store.commit('setUsage', { });
      }
    };

    return this.fetchUsageAsync = fetch();
  }

  async fetchUsers(userIds, options = { ignorePermissionErrors: false }) {
    try {
      const usersToFetchMap = userIds.reduce((agg, userId) => { agg[userId] = true; return agg; }, {});
      await Promise.all(Object.keys(usersToFetchMap).map(async userId => {
        if (userId.match(/^sc_/)) {
          // Skip clientIds from Authress
          return;
        }
        const convertedUserId = userId.replace(/^Authress\|/i, '');
        if (store.state.cache.userMap[convertedUserId]) {
          return;
        }

        try {
          await this.authressClient.getUser(convertedUserId, options);
        } catch (error) {
          logger.debug({ title: 'Failed to lookup user', userId, error });
        }
      }));
    } catch (error) {
      logger.error({ title: 'Failed to populate user lookups', error });
    }
  }

  async fetchAuthorizationEventsUsage() {
    const results = await this.authressClient.getAccountEvents();
    await auditEventRepository.insertNewAuditEvents(results);
    return results;
  }
}
