import store from '../store';
import buildInfo from '../buildInfo';
import { cloneDeep } from 'lodash';
import stringify from 'json-stringify-safe';
import logger from '@/clients/logger';

const version = buildInfo.version;

function getStore() {
  const sanitizeStore = cloneDeep(store.state || {});
  delete sanitizeStore.profile;
  const account = store.state.cache.currentAccount;
  delete sanitizeStore.cache;
  sanitizeStore.account = account;
  return sanitizeStore;
}
export default function(Vue, window, router) {
  Vue.config.errorHandler = function(rawError, rawComponent, info) {
    const component = rawComponent || {};
    const vueComponent = { id: component.id, attributes: component.$attrs, refs: component.$refs, props: component.$props, tag: component.tag };
    const error = rawError || {};

    if (error.code === 'ECONNABORTED') {
      logger.log({ title: `VueWarning - ${error.message} - ${error.filename}@${error.lineNumber}:${error.columnNumber}`, error, stack: error.stack || '(unknown stack)', vueComponent, info });
      return;
    }

    logger.error({
      title: `VueException - ${error.message} - ${error.filename}@${error.lineNumber}:${error.columnNumber}`, error, stack: error.stack || '(unknown stack)',
      vueComponent, info, store: getStore()
    });
  };

  // Only show during non-production builds
  Vue.config.warnHandler = function(warning, rawComponent, info) {
    const component = rawComponent || {};
    const vueComponent = { id: component.id, attributes: component.$attrs, refs: component.$refs, props: component.$props, tag: component.tag };
    logger.error({ title: 'VueWarning', warning, vueComponent, info, store: getStore() });
  };

  router.onError(async error => {
    if (error.message?.match(/loading.*chunk [\w.]* failed./i)) {
      const errorData = store.state.cache.errorTracking.chunkLoadingError || {};
      const count = (errorData?.count || 0) + 1;
      const errorVersion = errorData?.version;
      if (!errorVersion?.releaseDate || errorData?.releaseDate !== version?.releaseDate || count < 5) {
        store.commit('trackChunkLoadingError', { version, count });
        logger.warn({ title: 'ChunkLoadFailure', error, version, currentChunkError: errorData });
        await new Promise(resolve => setTimeout(resolve, 100 * 2 ** count));
        window.location.reload(true);
        return;
      }

      logger.error({ title: 'VueRouterException - ChunkLoadFailure', error, version, currentChunkError: errorData });
      // eslint-disable-next-line no-alert
      if (confirm('This site failed to load due to an issue with your network connection. The site may not fully work correctly. To ensure a complete load, check your network configuration. For advanced debugging, review the network tab and inspector logs.\n\nPress OK to force a reload of the site.\nPress Cancel to continue to use as is.')) {
        window.location.reload(true);
      }
      return;
    }

    logger.error({ title: 'VueRouterException', error, version, errorTracking: store.state.cache.errorTracking });
  });

  const onerror = window.onerror;
  window.onerror = function(msg, url, line, col, error) {
    const returnFunction = () => {
      if (onerror && typeof onerror === 'function') {
        onerror.apply(window, arguments);
      }
    };

    // https://stackoverflow.com/a/72609589/5091874 https://github.com/nodejs/node/issues/38795
    if (typeof msg === 'string' && msg?.match('Possible side-effect in debug-evaluate')) {
      return null;
    }

    // No idea where this is coming from, but we don't use googletags
    if (typeof msg === 'string' && msg?.match('googletag')) {
      return null;
    }

    // Skip issues with chrome extensions that we don't own
    if (typeof url === 'string' && url?.match('chrome-extension')) {
      return null;
    }

    if (error?.message === "Unexpected token '<'" && url?.match(/[.]js$/)) {
      logger.warn({ title: 'The specific website file for Authress could not be loaded. This is likely because the file could not be found. When this happens we fallback to loading the default S3 index.html file through the CF Distribution error document. Then because we expected an JSON file, we parse it. JSON.parse("<...") throws this exact error. This usually happens either when changing the origin, which happens at the beginning of the year. It should never happen outside of that, unless there is a real problem. So we will log a WARN. Anything more serious will have to be reported via another mechanism.', store: getStore(), exception: { message: msg, url: url, line_num: line, col_num: col, error: error || 'No Error specified' } }, false);
      window.location.reload(true);
    }

    logger.error({ title: 'GlobalException', store: getStore(), exception: { message: msg, url: url, line_num: line, col_num: col, error: error || 'No Error specified' } });

    return returnFunction();
  };

  const onunhandledrejection = window.onunhandledrejection;
  window.onunhandledrejection = function(error) {
    const summary = { message: error?.message, arguments: error?.arguments, type: error?.type, code: error?.code, reason: error?.reason, name: error?.name };
    if (typeof error?.reason?.stack === 'string' && error.reason.stack.match('chrome-extension')) {
      const extensionId = error.reason.stack.match(/chrome-extension:\/\/([^/]*)(\/|$)/)?.[1];
      logger.warn({
        title: `GlobalPromiseRejection - ${stringify(summary?.reason || '')}`,
        store: getStore(),
        extensionId,
        summary,
        rejectedPromise: error?.promise,
        exception: error
      });
      // eslint-disable-next-line no-alert
      alert(`Oops, the browser extension (id: ${extensionId}) failed to load and may prevent this site from working correctly. Try temporarily disabling it.`);
      return;
    }

    if (typeof error?.reason === 'string' && error.reason.match('Object Not Found Matching')) {
      logger.warn({
        title: 'GlobalPromiseRejection - Caught the loop problem!',
        store: getStore(),
        summary,
        rejectedPromise: error && error.promise,
        exception: error
      }, false);
      // eslint-disable-next-line no-alert
      alert('This site failed to load because one of your browser extensions is blocking the load. For advanced debugging, review the network tab and inspector logs.\n\nIf you repeatedly see this message please reach out to developers@authress.io for assistance.');
      window.location.reload(true);
      return;
    }

    let method = 'error';
    if (summary.reason === 'Not implemented on this platform') {
      method = 'track';
    }
    if (summary.reason?.sourceURL?.match(/user-script:/)) {
      method = 'warn';
    }

    logger[method]({
      title: `GlobalPromiseRejection - ${stringify(summary.reason || '')}`,
      store: getStore(),
      summary,
      rejectedPromise: error?.promise,
      exception: error
    });

    if (onunhandledrejection && typeof onunhandledrejection === 'function') {
      onunhandledrejection.apply(window, arguments);
    }
  };
}
